/**
 * @generated SignedSource<<1c0b0de939ecbe5fc805d6096ceba127>>
 * @relayHash 5edc109846e2ab57cb9baa48e241d845
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 5ba739e82845a4af8ea03eed56bf5e521d73104e3c0e55784e6f61d9d3f2c644

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { JourneyConfigurationPageQuery } from './JourneyConfigurationPageQuery.graphql';

const node: PreloadableConcreteRequest<JourneyConfigurationPageQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "5ba739e82845a4af8ea03eed56bf5e521d73104e3c0e55784e6f61d9d3f2c644",
    "metadata": {},
    "name": "JourneyConfigurationPageQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
