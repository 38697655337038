import React, { type ReactNode, useMemo, createContext, useContext } from 'react';
import { type PreloadedQuery, graphql, useFragment, usePreloadedQuery } from 'react-relay';
import type { ExternalError, ExternalMessage } from '@atlaskit/jql-editor';
import type { FieldDataMap } from '@atlassian/jira-jql-builder-basic/src/common/types.tsx';
import { isQueryTooComplex } from '@atlassian/jira-jql-builder-basic/src/utils/is-query-too-complex/index.tsx';
import { mapOperators } from '@atlassian/jira-jql-builder-basic/src/utils/operators/map-operators.tsx';
import {
	type PreloadedHydrateJqlBuilderQuery,
	HYDRATION_QUERY,
} from '@atlassian/jira-jql-builder-common/src/ui/hydrate-jql-builder/index.tsx';
import { JQL_BUILDER_PERFORMANCE_MARKS } from '@atlassian/jira-jql-builder-common/src/ui/performance-context/constants.tsx';
import { usePerformanceContext } from '@atlassian/jira-jql-builder-common/src/ui/performance-context/index.tsx';
import type { hydrateJqlBuilderQuery } from '@atlassian/jira-relay/src/__generated__/hydrateJqlBuilderQuery.graphql';
import type { ui_jqlBuilder_JQLBuilderUI_hydrationQueryData$data } from '@atlassian/jira-relay/src/__generated__/ui_jqlBuilder_JQLBuilderUI_hydrationQueryData.graphql';
import type {
	useQuery$key,
	useQuery$data,
} from '@atlassian/jira-relay/src/__generated__/useQuery.graphql';

import { fg } from '@atlassian/jira-feature-gating';
import { useHydrationQuery } from '../../services/hydration-query/index.tsx';

// Inline query when cleaning up nin_migrate_jql_builder_to_refetchable_query
/* eslint-disable @atlassian/relay/graphql-naming, @atlassian/relay/unused-fields */
export const USE_QUERY_FRAGMENT = graphql`
	fragment useQuery on Query {
		jira @include(if: $includeJira) {
			jqlBuilder(cloudId: $cloudId) {
				hydrateJqlQuery(query: $jql, viewContext: $viewContext) @skip(if: $isFilter) {
					... on JiraJqlHydratedQuery {
						fields {
							... on JiraJqlQueryHydratedField {
								jqlTerm
								field {
									operators
									searchTemplate {
										key
									}
									type
								}
								values {
									... on JiraJqlQueryHydratedValue {
										__typename
										jqlTerm
										values {
											... on JiraJqlCascadingOptionFieldValue {
												__typename
												jqlTerm
												displayName
												optionId
												parentOption {
													displayName
													jqlTerm
													optionId
												}
											}
										}
									}
									... on JiraJqlQueryHydratedError {
										__typename
										jqlTerm
									}
								}
							}
						}
					}
				}
				hydrateJqlQueryForFilter(id: $filterAri, viewContext: $viewContext)
					@include(if: $isFilter) {
					... on JiraJqlHydratedQuery {
						fields {
							... on JiraJqlQueryHydratedField {
								jqlTerm
								field {
									operators
									searchTemplate {
										key
									}
									type
								}
								values {
									... on JiraJqlQueryHydratedValue {
										__typename
										jqlTerm
										values {
											... on JiraJqlCascadingOptionFieldValue {
												__typename
												jqlTerm
												displayName
												optionId
												parentOption {
													displayName
													jqlTerm
													optionId
												}
											}
										}
									}
									... on JiraJqlQueryHydratedError {
										__typename
										jqlTerm
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
/* eslint-enable @atlassian/relay/graphql-naming,  @atlassian/relay/unused-fields */

const useQuerySupportedOld = ({
	currentJql,
	excludedFields = [],
	jqlErrors,
	hydrationQueryRef,
	isHydrating,
	simplifiedOperators,
	hideTextSearchInput,
	contextJql,
}: {
	currentJql: string;
	excludedFields: string[];
	jqlErrors?: ExternalError[];
	hydrationQueryRef: PreloadedQuery<hydrateJqlBuilderQuery>;
	isHydrating: boolean;
	simplifiedOperators: boolean;
	hideTextSearchInput: boolean;
	contextJql: string;
}) => {
	const onPerformanceMark = usePerformanceContext();

	onPerformanceMark({ mark: JQL_BUILDER_PERFORMANCE_MARKS.JQL_BUILDER_HYDRATE_START });
	/* eslint-disable @atlassian/relay/query-restriction */
	const hydratedData = usePreloadedQuery<hydrateJqlBuilderQuery>(
		HYDRATION_QUERY,
		hydrationQueryRef,
	);
	onPerformanceMark({ mark: JQL_BUILDER_PERFORMANCE_MARKS.JQL_BUILDER_HYDRATE_END });

	const fieldsHydrationData: useQuery$data = useFragment<useQuery$key>(
		USE_QUERY_FRAGMENT,
		hydratedData,
	);
	/* eslint-enable @atlassian/relay/query-restriction */

	return useMemo(() => {
		const fieldsData: FieldDataMap = {};

		if (fieldsHydrationData?.jira?.jqlBuilder?.hydrateJqlQuery?.fields) {
			fieldsHydrationData.jira.jqlBuilder.hydrateJqlQuery.fields.forEach((field) => {
				if (field?.jqlTerm && field?.field?.searchTemplate?.key && field?.field.type) {
					fieldsData[field.jqlTerm] = {
						jqlTerm: field.jqlTerm,
						searchTemplate: field.field.searchTemplate.key,
						operators: field.field.operators.map(mapOperators),
						fieldType: field.field.type,
						hydratedValues: field.values,
					};
				}
			});
		} else if (fieldsHydrationData?.jira?.jqlBuilder?.hydrateJqlQueryForFilter?.fields) {
			fieldsHydrationData.jira.jqlBuilder.hydrateJqlQueryForFilter.fields.forEach((field) => {
				if (field?.jqlTerm && field?.field?.searchTemplate?.key && field?.field.type) {
					fieldsData[field.jqlTerm] = {
						jqlTerm: field.jqlTerm,
						searchTemplate: field.field.searchTemplate.key,
						operators: field.field.operators.map(mapOperators),
						fieldType: field.field.type,
						hydratedValues: field.values,
					};
				}
			});
		}

		const isLastHydrationQuery = contextJql !== hydrationQueryRef?.variables.jql;
		let querySupported = true;

		if (fg('nin_shortcut_jql_complexity_check_on_error')) {
			querySupported =
				!jqlErrors?.length &&
				/**
				 * Relay run one render iteration with isHydrating false but with the old data,
				 * so we check if the jql matches the current hydration query.
				 */
				(isHydrating || isLastHydrationQuery
					? /**
						 * If we are hydrating, we run a complexity check without checking operators.
						 * This allow us to show advanced mode loading state if we already know the query is too complex.
						 * For Example, when we have multiple compound clauses or have unsupported nodes
						 */
						!isQueryTooComplex({
							jql: currentJql,
							excludedFields,
							shouldValidateOperators: false,
							hideTextSearchInput,
						})
					: /**
						 * Complete complexity check using hydrated data.
						 */
						!isQueryTooComplex({
							jql: currentJql,
							excludedFields,
							fieldsData,
							simplifiedOperators,
							hideTextSearchInput,
						}));
		} else {
			querySupported =
				/**
				 * Relay run one render iteration with isHydrating false but with the old data,
				 * so we check if the jql matches the current hydration query.
				 */
				isHydrating || isLastHydrationQuery
					? /**
						 * If we are hydrating, we run a complexity check without checking operators.
						 * This allow us to show advanced mode loading state if we already know the query is too complex.
						 * For Example, when we have multiple compound clauses or have unsupported nodes
						 */
						!isQueryTooComplex({
							jql: currentJql,
							excludedFields,
							shouldValidateOperators: false,
							hideTextSearchInput,
						})
					: /**
						 * Complete complexity check using hydrated data.
						 */
						!isQueryTooComplex({
							jql: currentJql,
							excludedFields,
							fieldsData,
							simplifiedOperators,
							hideTextSearchInput,
						}) && !jqlErrors?.length;
		}

		return { querySupported };
	}, [
		fieldsHydrationData?.jira?.jqlBuilder?.hydrateJqlQuery?.fields,
		fieldsHydrationData?.jira?.jqlBuilder?.hydrateJqlQueryForFilter?.fields,
		contextJql,
		hydrationQueryRef?.variables.jql,
		currentJql,
		isHydrating,
		excludedFields,
		hideTextSearchInput,
		simplifiedOperators,
		jqlErrors?.length,
	]);
};

export type PropsOld = {
	children: ReactNode;
	query: string;
	excludedFields?: string[];
	jqlMessages?: ExternalMessage[];
	hydrationQueryRef: PreloadedHydrateJqlBuilderQuery;
	isHydrating?: boolean;
	simplifiedOperators?: boolean;
	hideTextSearchInput?: boolean;
	contextJql: string;
};

export const QuerySupportedContainerOld = ({
	query,
	excludedFields = [],
	jqlMessages,
	children,
	hydrationQueryRef,
	isHydrating = false,
	simplifiedOperators = false,
	hideTextSearchInput = false,
	contextJql,
}: PropsOld) => {
	const jqlErrors = useMemo(
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		() => (jqlMessages || []).filter((e) => e.type === 'error') as ExternalError[],
		[jqlMessages],
	);

	const { querySupported } = useQuerySupportedOld({
		currentJql: query,
		excludedFields,
		jqlErrors,
		hydrationQueryRef,
		isHydrating,
		simplifiedOperators,
		hideTextSearchInput,
		contextJql,
	});

	const contextValue: ContextType = useMemo(
		() => ({
			querySupported,
		}),
		[querySupported],
	);

	return (
		<QuerySupportedContext.Provider value={contextValue}>{children}</QuerySupportedContext.Provider>
	);
};

const useQuerySupported = ({
	currentJql,
	excludedFields = [],
	jqlErrors,
	hydrationData,
	simplifiedOperators,
	hideTextSearchInput,
	contextJql,
}: {
	currentJql: string;
	excludedFields: string[];
	jqlErrors?: ExternalError[];
	hydrationData: ui_jqlBuilder_JQLBuilderUI_hydrationQueryData$data;
	simplifiedOperators: boolean;
	hideTextSearchInput: boolean;
	contextJql: string;
}) => {
	/* eslint-disable @atlassian/relay/query-restriction */
	const fieldsHydrationData: useQuery$data = useFragment<useQuery$key>(
		USE_QUERY_FRAGMENT,
		hydrationData,
	);
	/* eslint-enable @atlassian/relay/query-restriction */

	const { hydrationQueryIsFetching, hydrationQueryContextJql } = useHydrationQuery();

	return useMemo(() => {
		const fieldsData: FieldDataMap = {};

		if (fieldsHydrationData?.jira?.jqlBuilder?.hydrateJqlQuery?.fields) {
			fieldsHydrationData.jira.jqlBuilder.hydrateJqlQuery.fields.forEach((field) => {
				if (field?.jqlTerm && field?.field?.searchTemplate?.key && field?.field.type) {
					fieldsData[field.jqlTerm] = {
						jqlTerm: field.jqlTerm,
						searchTemplate: field.field.searchTemplate.key,
						operators: field.field.operators.map(mapOperators),
						fieldType: field.field.type,
						hydratedValues: field.values,
					};
				}
			});
		} else if (fieldsHydrationData?.jira?.jqlBuilder?.hydrateJqlQueryForFilter?.fields) {
			fieldsHydrationData.jira.jqlBuilder.hydrateJqlQueryForFilter.fields.forEach((field) => {
				if (field?.jqlTerm && field?.field?.searchTemplate?.key && field?.field.type) {
					fieldsData[field.jqlTerm] = {
						jqlTerm: field.jqlTerm,
						searchTemplate: field.field.searchTemplate.key,
						operators: field.field.operators.map(mapOperators),
						fieldType: field.field.type,
						hydratedValues: field.values,
					};
				}
			});
		}

		const isLastHydrationQuery = contextJql !== hydrationQueryContextJql;
		let querySupported = true;

		if (fg('nin_shortcut_jql_complexity_check_on_error')) {
			querySupported =
				!jqlErrors?.length &&
				/**
				 * Relay run one render iteration with isHydrating false but with the old data,
				 * so we check if the jql matches the current hydration query.
				 */
				(hydrationQueryIsFetching || isLastHydrationQuery
					? /**
						 * If we are hydrating, we run a complexity check without checking operators.
						 * This allow us to show advanced mode loading state if we already know the query is too complex.
						 * For Example, when we have multiple compound clauses or have unsupported nodes
						 */
						!isQueryTooComplex({
							jql: currentJql,
							excludedFields,
							shouldValidateOperators: false,
							hideTextSearchInput,
						})
					: /**
						 * Complete complexity check using hydrated data.
						 */
						!isQueryTooComplex({
							jql: currentJql,
							excludedFields,
							fieldsData,
							simplifiedOperators,
							hideTextSearchInput,
						}));
		} else {
			querySupported =
				/**
				 * Relay run one render iteration with isHydrating false but with the old data,
				 * so we check if the jql matches the current hydration query.
				 */
				hydrationQueryIsFetching || isLastHydrationQuery
					? /**
						 * If we are hydrating, we run a complexity check without checking operators.
						 * This allow us to show advanced mode loading state if we already know the query is too complex.
						 * For Example, when we have multiple compound clauses or have unsupported nodes
						 */
						!isQueryTooComplex({
							jql: currentJql,
							excludedFields,
							shouldValidateOperators: false,
							hideTextSearchInput,
						})
					: /**
						 * Complete complexity check using hydrated data.
						 */
						!isQueryTooComplex({
							jql: currentJql,
							excludedFields,
							fieldsData,
							simplifiedOperators,
							hideTextSearchInput,
						}) && !jqlErrors?.length;
		}

		return { querySupported };
	}, [
		fieldsHydrationData?.jira?.jqlBuilder?.hydrateJqlQuery?.fields,
		fieldsHydrationData?.jira?.jqlBuilder?.hydrateJqlQueryForFilter?.fields,
		contextJql,
		hydrationQueryContextJql,
		hydrationQueryIsFetching,
		currentJql,
		excludedFields,
		hideTextSearchInput,
		simplifiedOperators,
		jqlErrors?.length,
	]);
};

export type Props = {
	children: ReactNode;
	query: string;
	excludedFields?: string[];
	jqlMessages?: ExternalMessage[];
	hydrationData: ui_jqlBuilder_JQLBuilderUI_hydrationQueryData$data;
	isHydrating?: boolean;
	simplifiedOperators?: boolean;
	hideTextSearchInput?: boolean;
	contextJql: string;
};

type ContextType = {
	querySupported: boolean;
};

const QuerySupportedContext = createContext<ContextType>({
	querySupported: true,
});

export const QuerySupportedContainer = ({
	query,
	excludedFields = [],
	jqlMessages,
	children,
	hydrationData,
	simplifiedOperators = false,
	hideTextSearchInput = false,
	contextJql,
}: Props) => {
	const jqlErrors = useMemo(
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		() => (jqlMessages || []).filter((e) => e.type === 'error') as ExternalError[],
		[jqlMessages],
	);

	const { querySupported } = useQuerySupported({
		currentJql: query,
		excludedFields,
		jqlErrors,
		hydrationData,
		simplifiedOperators,
		hideTextSearchInput,
		contextJql,
	});

	const contextValue: ContextType = useMemo(
		() => ({
			querySupported,
		}),
		[querySupported],
	);

	return (
		<QuerySupportedContext.Provider value={contextValue}>{children}</QuerySupportedContext.Provider>
	);
};

export const useIsQuerySupported = () => useContext(QuerySupportedContext);
