import React, { useCallback, useMemo } from 'react';
import { useNavigationItemAnalytics } from '@atlassian/jira-navigation-apps-topnav4-common/src/controllers/navigation-item-analytics/main.tsx';
import { NAVIGATION_ITEM_ID__APP_SWITCHER } from '@atlassian/jira-navigation-apps-topnav4-common/src/constants.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { useTopMenus } from '@atlassian/jira-navigation-apps-common/src/controllers/top-menus/index.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { AppSwitcherIntegrationEntrypoint } from '../integration/entrypoint.tsx';
import { AppSwitcherPlaceholderButton } from './AppSwitcherPlaceholderButton.tsx';
import { AppSwitcherPlaceholderWithLoadingPopup } from './AppSwitcherPlaceholderWithLoadingPopup.tsx';

export function AppSwitcherTrigger() {
	const [isOpen, { on: openMenu, off: closeMenu }] = useTopMenus(MENU_ID.APP_SWITCHER);
	const triggerAnalytics = useNavigationItemAnalytics({
		navigationItemId: NAVIGATION_ITEM_ID__APP_SWITCHER,
	});
	const onOpen = useCallback(() => {
		openMenu();
		triggerAnalytics();
	}, [openMenu, triggerAnalytics]);

	const entryPointParams = useMemo(
		() => ({ isOpen, onClose: closeMenu, onOpen }),
		[isOpen, closeMenu, onOpen],
	);
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		AppSwitcherIntegrationEntrypoint,
		entryPointParams,
	);
	const entrypointRef = useEntryPointButtonTrigger(entryPointActions);

	return (
		<>
			{!isOpen ? (
				<AppSwitcherPlaceholderButton onOpen={onOpen} ref={entrypointRef} />
			) : (
				<JiraEntryPointContainer
					entryPointReferenceSubject={entryPointReferenceSubject}
					id="app-switcher-content"
					packageName="jira-navigation-apps-topnav4-app-switcher"
					fallback={<AppSwitcherPlaceholderWithLoadingPopup />}
					errorFallback="flag"
					runtimeProps={entryPointParams}
				/>
			)}
		</>
	);
}
