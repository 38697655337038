import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { jiraHorizontalNavEntrypoint } from '@atlassian/jira-horizontal-nav/entrypoint.tsx';
import { softwareGoalsEntrypoint } from '@atlassian/jira-spa-apps-goals/entrypoint.tsx';

export const softwareGoalsPageEntrypoint = createPageEntryPoint({
	main: softwareGoalsEntrypoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
	horizontalNavigation: jiraHorizontalNavEntrypoint,
});
