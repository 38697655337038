{
  "admin-hub.ci-test.message-template-template-hello-world": [
    {
      "type": 0,
      "value": "Hello "
    },
    {
      "type": 1,
      "value": "propA"
    },
    {
      "type": 0,
      "value": ". Is is a lovely "
    },
    {
      "type": 1,
      "value": "propB"
    }
  ],
  "ci-generation.email.body": [
    {
      "type": 0,
      "value": "This is a test message from the "
    },
    {
      "type": 1,
      "value": "env"
    },
    {
      "type": 0,
      "value": " environment."
    }
  ],
  "ci-generation.email.body.hello": [
    {
      "type": 0,
      "value": "Hi "
    },
    {
      "type": 1,
      "value": "name"
    },
    {
      "type": 0,
      "value": ","
    }
  ],
  "ci-generation.email.body.img.alt": [
    {
      "type": 0,
      "value": "This is the alt text for the image"
    }
  ],
  "ci-generation.email.body.regards": [
    {
      "type": 0,
      "value": "Team Post Office"
    }
  ],
  "ci-generation.email.body.signoff": [
    {
      "type": 0,
      "value": "Regards,"
    }
  ],
  "ci-generation.email.subject": [
    {
      "type": 0,
      "value": "Post Office Test Message from "
    },
    {
      "type": 1,
      "value": "env"
    }
  ],
  "ci-generation.email.unsubscribe.text": [
    {
      "type": 0,
      "value": "Unsubscribe"
    }
  ],
  "post-office.test-message-digest-email-template.test-message.email.body.digest-title": [
    {
      "type": 0,
      "value": "You have "
    },
    {
      "type": 1,
      "value": "numberOfMsg"
    },
    {
      "type": 0,
      "value": " new messages!"
    }
  ],
  "post-office.test-message-digest-email-template.test-message.email.body.message-body": [
    {
      "type": 1,
      "value": "defaultMessage"
    }
  ],
  "post-office.test-message.email.subject": [
    {
      "type": 0,
      "value": "Post Office Test Message from "
    },
    {
      "type": 1,
      "value": "env"
    },
    {
      "type": 0,
      "value": "."
    }
  ],
  "post-office.test-messages.test-message-load-test-push-flow-email-mobile-push.sample-mobile-notification-message.no-translate": [
    {
      "type": 0,
      "value": "Hello, this is a mobile push notification test from post office."
    }
  ],
  "post-office.test-messages.test-message-mobile-push-jira-ugc.redacted.sample-mobile-notification-message.no-translate": [
    {
      "type": 0,
      "value": "Hello, this is a redacted test mobile push notification from post office."
    }
  ],
  "post-office.test-messages.test-message-mobile-push-jira-ugc.sample-mobile-notification-message.no-translate": [
    {
      "type": 0,
      "value": "Hello, this is a mobile push notification test from post office."
    }
  ],
  "post-office.test-messages.test-message-mobile-push.greeting.no-translate": [
    {
      "type": 0,
      "value": "Hello, this is a mobile push notification test from post office."
    }
  ],
  "post-office.test-messages.test-message-mobile-push.redacted.greeting.no-translate": [
    {
      "type": 0,
      "value": "Hello, this is a redacted mobile push notification test from post office."
    }
  ],
  "post-office.test-messages.test-message-transform-metadata.sample-hello-message.no-translate": [
    {
      "type": 0,
      "value": "Hello, *This is notification test from post office*"
    }
  ],
  "post-office.test-slack-notification.sample-hello-message": [
    {
      "type": 0,
      "value": "Hello, *This is notification test from post office*"
    }
  ],
  "product-store-personal-use-case.hello-world": [
    {
      "type": 0,
      "value": "Hello. Is is a lovely"
    }
  ],
  "test-messages.app-switcher-discovery-section.dismiss": [
    {
      "type": 0,
      "value": "Dismiss"
    }
  ],
  "test-messages.app-switcher-discovery-section.test-content": [
    {
      "type": 0,
      "value": "This is a test message."
    }
  ],
  "test-messages.app-switcher-upgrade-section.dismiss": [
    {
      "type": 0,
      "value": "Dismiss"
    }
  ],
  "test-messages.app-switcher-upgrade-section.test-content": [
    {
      "type": 0,
      "value": "This is a test message."
    }
  ],
  "test-messages.email.body.regards": [
    {
      "type": 0,
      "value": "Team Post Office"
    }
  ],
  "test-messages.in-app-example.hello-world": [
    {
      "type": 0,
      "value": "Hello "
    },
    {
      "type": 1,
      "value": "propA"
    },
    {
      "type": 0,
      "value": ". Is is a lovely "
    },
    {
      "type": 1,
      "value": "propB"
    }
  ],
  "test-messages.jira-navigation-topbar-edition-awareness.hello-world": [
    {
      "type": 0,
      "value": "Hello "
    },
    {
      "type": 1,
      "value": "propA"
    },
    {
      "type": 0,
      "value": ". Is is a lovely "
    },
    {
      "type": 1,
      "value": "propB"
    }
  ],
  "test-messages.redacted-email.body.regards": [
    {
      "type": 0,
      "value": "Team Post Office"
    }
  ],
  "test-messages.team-message-email-ugc.message-template.email.redacted.subject.no-translate": [
    {
      "type": 0,
      "value": "Post Office Redacted Test Message from "
    },
    {
      "type": 1,
      "value": "env"
    }
  ],
  "test-messages.test-message-digest-email.message-template-template.email.body.img.alt.no-translate": [
    {
      "type": 0,
      "value": "Description of image"
    }
  ],
  "test-messages.test-message-digest-email.message-template.email.subject.no-translate": [
    {
      "type": 0,
      "value": "Post Office Test Message from "
    },
    {
      "type": 1,
      "value": "env"
    }
  ],
  "test-messages.test-message-digest-email.test-message.email.body.hello.no-translate": [
    {
      "type": 0,
      "value": "Hey "
    },
    {
      "type": 1,
      "value": "name"
    },
    {
      "type": 0,
      "value": ","
    }
  ],
  "test-messages.test-message-digest-email.test-message.email.body.no-translate": [
    {
      "type": 0,
      "value": "This is a test message from the "
    },
    {
      "type": 1,
      "value": "env"
    },
    {
      "type": 0,
      "value": " environment. Here is your daily digest (rick) rollup for the "
    },
    {
      "type": 1,
      "value": "site"
    },
    {
      "type": 0,
      "value": " workspace"
    }
  ],
  "test-messages.test-message-digest-email.test-message.email.body.regards.no-translate": [
    {
      "type": 0,
      "value": "Team Post Office"
    }
  ],
  "test-messages.test-message-digest-email.test-message.email.body.signoff.no-translate": [
    {
      "type": 0,
      "value": "Regards,"
    }
  ],
  "test-messages.test-message-digest-email.test-message.email.unsubscribe.text.no-translate": [
    {
      "type": 0,
      "value": "Unsubscribe"
    }
  ],
  "test-messages.test-message-email-cdn.message-template-template.email.body.img.alt": [
    {
      "type": 0,
      "value": "Description of image"
    }
  ],
  "test-messages.test-message-email-cdn.message-template.email.subject": [
    {
      "type": 0,
      "value": "Post Office Test Message from "
    },
    {
      "type": 1,
      "value": "env"
    }
  ],
  "test-messages.test-message-email-cdn.test-message.email.body": [
    {
      "type": 0,
      "value": "This is a test message from the "
    },
    {
      "type": 1,
      "value": "env"
    },
    {
      "type": 0,
      "value": " environment."
    }
  ],
  "test-messages.test-message-email-cdn.test-message.email.body.hello": [
    {
      "type": 0,
      "value": "Hi "
    },
    {
      "type": 1,
      "value": "name"
    },
    {
      "type": 0,
      "value": ","
    }
  ],
  "test-messages.test-message-email-cdn.test-message.email.body.regards": [
    {
      "type": 0,
      "value": "Team Post Office"
    }
  ],
  "test-messages.test-message-email-cdn.test-message.email.body.signoff": [
    {
      "type": 0,
      "value": "Regards,"
    }
  ],
  "test-messages.test-message-email-cdn.test-message.email.unsubscribe.text": [
    {
      "type": 0,
      "value": "Unsubscribe"
    }
  ],
  "test-messages.test-message-email-mfc.message-template-template.email.body.img.alt.no-translate": [
    {
      "type": 0,
      "value": "Description of image"
    }
  ],
  "test-messages.test-message-email-mfc.message-template.email.subject.no-translate": [
    {
      "type": 0,
      "value": "Post Office Test Message from "
    },
    {
      "type": 1,
      "value": "env"
    }
  ],
  "test-messages.test-message-email-mfc.test-message.email.body.hello.no-translate": [
    {
      "type": 0,
      "value": "Hi "
    },
    {
      "type": 1,
      "value": "name"
    },
    {
      "type": 0,
      "value": ","
    }
  ],
  "test-messages.test-message-email-mfc.test-message.email.body.no-translate": [
    {
      "type": 0,
      "value": "This is a test message from the "
    },
    {
      "type": 1,
      "value": "env"
    },
    {
      "type": 0,
      "value": " environment."
    }
  ],
  "test-messages.test-message-email-mfc.test-message.email.body.regards.no-translate": [
    {
      "type": 0,
      "value": "Team Post Office"
    }
  ],
  "test-messages.test-message-email-mfc.test-message.email.body.signoff.no-translate": [
    {
      "type": 0,
      "value": "Regards,"
    }
  ],
  "test-messages.test-message-email-mfc.test-message.email.unsubscribe.text.no-translate": [
    {
      "type": 0,
      "value": "Unsubscribe"
    }
  ],
  "test-messages.test-message-email-ugc.message-template-template.email.body.img.alt.no-translate": [
    {
      "type": 0,
      "value": "Description of image"
    }
  ],
  "test-messages.test-message-email-ugc.message-template-template.email.redacted.body.img.alt.no-translate": [
    {
      "type": 0,
      "value": "Description of image"
    }
  ],
  "test-messages.test-message-email-ugc.message-template.email.subject.no-translate": [
    {
      "type": 0,
      "value": "Post Office Test Message from "
    },
    {
      "type": 1,
      "value": "env"
    }
  ],
  "test-messages.test-message-email-ugc.test-message.email.body.hello.no-translate": [
    {
      "type": 0,
      "value": "Hi "
    },
    {
      "type": 1,
      "value": "name"
    },
    {
      "type": 0,
      "value": ","
    }
  ],
  "test-messages.test-message-email-ugc.test-message.email.body.no-translate": [
    {
      "type": 0,
      "value": "This is a test message from the "
    },
    {
      "type": 1,
      "value": "env"
    },
    {
      "type": 0,
      "value": " environment."
    }
  ],
  "test-messages.test-message-email-ugc.test-message.email.body.regards.no-translate": [
    {
      "type": 0,
      "value": "Team Post Office"
    }
  ],
  "test-messages.test-message-email-ugc.test-message.email.body.signoff.no-translate": [
    {
      "type": 0,
      "value": "Regards,"
    }
  ],
  "test-messages.test-message-email-ugc.test-message.email.redacted.body.hello.no-translate": [
    {
      "type": 0,
      "value": "Hi "
    },
    {
      "type": 1,
      "value": "name"
    },
    {
      "type": 0,
      "value": ","
    }
  ],
  "test-messages.test-message-email-ugc.test-message.email.redacted.body.no-translate": [
    {
      "type": 0,
      "value": "This is a redacted test message from the "
    },
    {
      "type": 1,
      "value": "env"
    },
    {
      "type": 0,
      "value": " environment."
    }
  ],
  "test-messages.test-message-email-ugc.test-message.email.redacted.body.regards.no-translate": [
    {
      "type": 0,
      "value": "Team Post Office"
    }
  ],
  "test-messages.test-message-email-ugc.test-message.email.redacted.body.signoff.no-translate": [
    {
      "type": 0,
      "value": "Regards,"
    }
  ],
  "test-messages.test-message-email-ugc.test-message.email.unsubscribe.text.no-translate": [
    {
      "type": 0,
      "value": "Unsubscribe"
    }
  ],
  "test-messages.test-message-grouped-recipients.message-template-template.email.body.img.alt": [
    {
      "type": 0,
      "value": "Description of image"
    }
  ],
  "test-messages.test-message-grouped-recipients.message-template.email.subject": [
    {
      "type": 0,
      "value": "Post Office Test Message from "
    },
    {
      "type": 1,
      "value": "env"
    }
  ],
  "test-messages.test-message-grouped-recipients.test-message.email.body": [
    {
      "type": 0,
      "value": "This is a test message from the "
    },
    {
      "type": 1,
      "value": "env"
    },
    {
      "type": 0,
      "value": " environment."
    }
  ],
  "test-messages.test-message-grouped-recipients.test-message.email.body.hello": [
    {
      "type": 0,
      "value": "Hello"
    }
  ],
  "test-messages.test-message-grouped-recipients.test-message.email.body.regards": [
    {
      "type": 0,
      "value": "Team Post Office"
    }
  ],
  "test-messages.test-message-grouped-recipients.test-message.email.body.signoff": [
    {
      "type": 0,
      "value": "Regards,"
    }
  ],
  "test-messages.test-message-grouped-recipients.test-message.email.unsubscribe.text": [
    {
      "type": 0,
      "value": "Unsubscribe"
    }
  ],
  "test-messages.test-message-inapp-engagement.message-template-template.email.body.img.alt.no-translate": [
    {
      "type": 0,
      "value": "Description of image"
    }
  ],
  "test-messages.test-message-inapp-engagement.message-template.email.subject.no-translate": [
    {
      "type": 0,
      "value": "Post Office Test Message from "
    },
    {
      "type": 1,
      "value": "env"
    }
  ],
  "test-messages.test-message-inapp-engagement.test-message.email.body.hello.no-translate": [
    {
      "type": 0,
      "value": "Hi "
    },
    {
      "type": 1,
      "value": "name"
    },
    {
      "type": 0,
      "value": ","
    }
  ],
  "test-messages.test-message-inapp-engagement.test-message.email.body.no-translate": [
    {
      "type": 0,
      "value": "This is a test message from the "
    },
    {
      "type": 1,
      "value": "env"
    },
    {
      "type": 0,
      "value": " environment."
    }
  ],
  "test-messages.test-message-inapp-engagement.test-message.email.body.regards.no-translate": [
    {
      "type": 0,
      "value": "Team Post Office"
    }
  ],
  "test-messages.test-message-inapp-engagement.test-message.email.body.signoff.no-translate": [
    {
      "type": 0,
      "value": "Regards,"
    }
  ],
  "test-messages.test-message-inapp-engagement.test-message.email.unsubscribe.text.no-translate": [
    {
      "type": 0,
      "value": "Unsubscribe"
    }
  ],
  "test-messages.test-message-load-test-push-flow-email-mobile-push.message-template-template.email.body.img.alt.no-translate": [
    {
      "type": 0,
      "value": "Description of image"
    }
  ],
  "test-messages.test-message-load-test-push-flow-email-mobile-push.message-template.email.subject.no-translate": [
    {
      "type": 0,
      "value": "Post Office Test Message from "
    },
    {
      "type": 1,
      "value": "env"
    }
  ],
  "test-messages.test-message-load-test-push-flow-email-mobile-push.test-message.email.body.hello.no-translate": [
    {
      "type": 0,
      "value": "Hi "
    },
    {
      "type": 1,
      "value": "name"
    },
    {
      "type": 0,
      "value": ","
    }
  ],
  "test-messages.test-message-load-test-push-flow-email-mobile-push.test-message.email.body.no-translate": [
    {
      "type": 0,
      "value": "This is a test message from the "
    },
    {
      "type": 1,
      "value": "env"
    },
    {
      "type": 0,
      "value": " environment."
    }
  ],
  "test-messages.test-message-load-test-push-flow-email-mobile-push.test-message.email.body.regards.no-translate": [
    {
      "type": 0,
      "value": "Team Post Office"
    }
  ],
  "test-messages.test-message-load-test-push-flow-email-mobile-push.test-message.email.body.signoff.no-translate": [
    {
      "type": 0,
      "value": "Regards,"
    }
  ],
  "test-messages.test-message-load-test-push-flow-email-mobile-push.test-message.email.unsubscribe.text.no-translate": [
    {
      "type": 0,
      "value": "Unsubscribe"
    }
  ],
  "test-messages.test-message-marketing.message-template-template.email.body.img.alt.no-translate": [
    {
      "type": 0,
      "value": "Description of image"
    }
  ],
  "test-messages.test-message-marketing.message-template.email.subject.no-translate": [
    {
      "type": 0,
      "value": "Post Office Test Message from "
    },
    {
      "type": 1,
      "value": "env"
    }
  ],
  "test-messages.test-message-marketing.test-message.email.body.hello.no-translate": [
    {
      "type": 0,
      "value": "Hi "
    },
    {
      "type": 1,
      "value": "name"
    },
    {
      "type": 0,
      "value": ","
    }
  ],
  "test-messages.test-message-marketing.test-message.email.body.no-translate": [
    {
      "type": 0,
      "value": "This is a test message from the "
    },
    {
      "type": 1,
      "value": "env"
    },
    {
      "type": 0,
      "value": " environment."
    }
  ],
  "test-messages.test-message-marketing.test-message.email.body.regards.no-translate": [
    {
      "type": 0,
      "value": "Team Post Office"
    }
  ],
  "test-messages.test-message-marketing.test-message.email.body.signoff.no-translate": [
    {
      "type": 0,
      "value": "Regards,"
    }
  ],
  "test-messages.test-message-marketing.test-message.email.unsubscribe.text.no-translate": [
    {
      "type": 0,
      "value": "Unsubscribe"
    }
  ],
  "test-messages.test-message-message-failures.message-template-template.email.body.img.alt.no-translate": [
    {
      "type": 0,
      "value": "Description of image"
    }
  ],
  "test-messages.test-message-message-failures.message-template.email.subject.no-translate": [
    {
      "type": 0,
      "value": "Post Office Test Message from "
    },
    {
      "type": 1,
      "value": "env"
    }
  ],
  "test-messages.test-message-message-failures.test-message.email.body.hello.no-translate": [
    {
      "type": 0,
      "value": "Hi "
    },
    {
      "type": 1,
      "value": "name"
    },
    {
      "type": 0,
      "value": ","
    }
  ],
  "test-messages.test-message-message-failures.test-message.email.body.no-translate": [
    {
      "type": 0,
      "value": "This is a test message from the "
    },
    {
      "type": 1,
      "value": "env"
    },
    {
      "type": 0,
      "value": " environment."
    }
  ],
  "test-messages.test-message-message-failures.test-message.email.body.regards.no-translate": [
    {
      "type": 0,
      "value": "Team Post Office"
    }
  ],
  "test-messages.test-message-message-failures.test-message.email.body.signoff.no-translate": [
    {
      "type": 0,
      "value": "Regards,"
    }
  ],
  "test-messages.test-message-message-failures.test-message.email.unsubscribe.text.no-translate": [
    {
      "type": 0,
      "value": "Unsubscribe"
    }
  ],
  "test-messages.test-message-persona-email.message-template-template.email.body.img.alt": [
    {
      "type": 0,
      "value": "Description of image"
    }
  ],
  "test-messages.test-message-persona-email.message-template.email.subject": [
    {
      "type": 0,
      "value": "Post Office Test Message from "
    },
    {
      "type": 1,
      "value": "env"
    }
  ],
  "test-messages.test-message-persona-email.test-message.email.body": [
    {
      "type": 0,
      "value": "This is a test message from the "
    },
    {
      "type": 1,
      "value": "env"
    },
    {
      "type": 0,
      "value": " environment."
    }
  ],
  "test-messages.test-message-persona-email.test-message.email.body.regards": [
    {
      "type": 0,
      "value": "Team Post Office"
    }
  ],
  "test-messages.test-message-persona-email.test-message.email.body.signoff": [
    {
      "type": 0,
      "value": "Regards,"
    }
  ],
  "test-messages.test-message-persona-email.test-message.email.unsubscribe.text": [
    {
      "type": 0,
      "value": "Unsubscribe"
    }
  ],
  "test-messages.test-message-raw-recipients.message-template-template.email.body.img.alt.no-translate": [
    {
      "type": 0,
      "value": "Description of image"
    }
  ],
  "test-messages.test-message-raw-recipients.message-template.email.subject.no-translate": [
    {
      "type": 0,
      "value": "Post Office Test Message from "
    },
    {
      "type": 1,
      "value": "env"
    }
  ],
  "test-messages.test-message-raw-recipients.test-message.email.body.greeting.no-translate": [
    {
      "type": 1,
      "value": "greeting"
    }
  ],
  "test-messages.test-message-raw-recipients.test-message.email.body.hello.name.no-translate": [
    {
      "type": 0,
      "value": "Hi "
    },
    {
      "type": 1,
      "value": "name"
    },
    {
      "type": 0,
      "value": ","
    }
  ],
  "test-messages.test-message-raw-recipients.test-message.email.body.hello.no-translate": [
    {
      "type": 0,
      "value": "Hi,"
    }
  ],
  "test-messages.test-message-raw-recipients.test-message.email.body.no-translate": [
    {
      "type": 0,
      "value": "This is a test message from the "
    },
    {
      "type": 1,
      "value": "env"
    },
    {
      "type": 0,
      "value": " environment."
    }
  ],
  "test-messages.test-message-raw-recipients.test-message.email.body.regards.no-translate": [
    {
      "type": 0,
      "value": "Team Post Office"
    }
  ],
  "test-messages.test-message-raw-recipients.test-message.email.body.signoff.no-translate": [
    {
      "type": 0,
      "value": "Regards,"
    }
  ],
  "test-messages.test-message-raw-recipients.test-message.email.unsubscribe.text.no-translate": [
    {
      "type": 0,
      "value": "Unsubscribe"
    }
  ],
  "test-messages.test-message-transform-metadata.message-template-template.email.body.img.alt.no-translate": [
    {
      "type": 0,
      "value": "Description of image"
    }
  ],
  "test-messages.test-message-transform-metadata.message-template.email.subject.no-translate": [
    {
      "type": 0,
      "value": "Post Office Test Message from "
    },
    {
      "type": 1,
      "value": "env"
    }
  ],
  "test-messages.test-message-transform-metadata.test-message.email.body.hello.no-translate": [
    {
      "type": 0,
      "value": "Hi "
    },
    {
      "type": 1,
      "value": "name"
    },
    {
      "type": 0,
      "value": ","
    }
  ],
  "test-messages.test-message-transform-metadata.test-message.email.body.no-translate": [
    {
      "type": 0,
      "value": "This is a test message from the "
    },
    {
      "type": 1,
      "value": "env"
    },
    {
      "type": 0,
      "value": " environment."
    }
  ],
  "test-messages.test-message-transform-metadata.test-message.email.body.regards.no-translate": [
    {
      "type": 0,
      "value": "Team Post Office"
    }
  ],
  "test-messages.test-message-transform-metadata.test-message.email.body.signoff.no-translate": [
    {
      "type": 0,
      "value": "Regards,"
    }
  ],
  "test-messages.test-message-transform-metadata.test-message.email.unsubscribe.text.no-translate": [
    {
      "type": 0,
      "value": "Unsubscribe"
    }
  ],
  "test-messages.test-message.app.img.alt.large": [
    {
      "type": 0,
      "value": "test image 2"
    }
  ],
  "test-messages.test-message.app.img.alt.plant": [
    {
      "type": 0,
      "value": "test image 1"
    }
  ],
  "test-messages.test-message.email.body": [
    {
      "type": 0,
      "value": "This is a test message from the "
    },
    {
      "type": 1,
      "value": "env"
    },
    {
      "type": 0,
      "value": " environment. "
    },
    {
      "type": 1,
      "value": "additionalBody"
    }
  ],
  "test-messages.test-message.email.body.hello": [
    {
      "type": 0,
      "value": "Hi Atlassian,"
    }
  ],
  "test-messages.test-message.email.body.img.alt": [
    {
      "type": 0,
      "value": "this is the alt for the test-message-email message template"
    }
  ],
  "test-messages.test-message.email.body.signoff": [
    {
      "type": 0,
      "value": "Regards,"
    }
  ],
  "test-messages.test-message.email.unsubscribe.text": [
    {
      "type": 0,
      "value": "Unsubscribe"
    }
  ],
  "test-messages.test-message.redacted-email.body": [
    {
      "type": 0,
      "value": "This is the redacted version of the test email message from the "
    },
    {
      "type": 1,
      "value": "env"
    },
    {
      "type": 0,
      "value": " environment. "
    },
    {
      "type": 1,
      "value": "additionalBody"
    }
  ],
  "test-messages.test-message.redacted-email.body.hello": [
    {
      "type": 0,
      "value": "Hi Atlassian,"
    }
  ],
  "test-messages.test-message.redacted-email.body.img.alt": [
    {
      "type": 0,
      "value": "this is the alt for the test-message-email message template"
    }
  ],
  "test-messages.test-message.redacted-email.body.signoff": [
    {
      "type": 0,
      "value": "Regards,"
    }
  ],
  "test-messages.test-message.redacted-email.unsubscribe.text": [
    {
      "type": 0,
      "value": "Unsubscribe"
    }
  ],
  "wac-global-nav-product-recommendation.hello-world": [
    {
      "type": 0,
      "value": "Hello. Is is a lovely"
    }
  ]
}