import { fg } from '@atlassian/jira-feature-gating';
import {
	getPermalinkId,
	getPermalinkIdFromURL,
	COMMENTS,
} from '../../../../common/utils/permalinks.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({
	maxResults,
	startAt,
	orderBy,
	commentAmount,
	hydrateData = true,
	url,
}: {
	startAt: number;
	maxResults?: number;
	orderBy?: string;
	commentAmount?: number;
	hydrateData?: boolean;
	url?: URL;
}) => {
	const commentPermalinkId =
		url && fg('jiv-19906-fix-targered-permalink-on-server')
			? getPermalinkIdFromURL(COMMENTS, url)
			: getPermalinkId(COMMENTS);

	const orderByParameter =
		orderBy !== null && orderBy !== undefined ? `, orderBy: "${orderBy}"` : '';

	const commentParameter =
		commentPermalinkId !== null && commentPermalinkId !== undefined
			? `, commentId: ${commentPermalinkId}`
			: '';

	const commentAmountParameter =
		commentParameter !== '' && commentAmount !== null && commentAmount !== undefined
			? `, commentAmount: ${commentAmount}`
			: '';

	const maxResultsParameter =
		commentParameter === '' && maxResults !== null && maxResults !== undefined
			? `, maxResults: ${maxResults}`
			: '';

	const jiraCommentVisibility = ` jiraCommentVisibility {
    __typename
    ...on JiraRoleVisibility {
      name
    }
    ...on JiraGroupVisibility {
      group {
        groupId
        name
      }
    }
  }`;
	if (fg('jira_comments_agg_pagination')) {
		return `
        comments (first: ${startAt}${maxResultsParameter}${orderByParameter}${commentParameter}${commentAmountParameter}) {
            totalCount
        }
    `;
	}
	if (!fg('jira_comments_agg_pagination_consistency_check') && !hydrateData) {
		return `
        comments (first: ${startAt}${maxResultsParameter}${orderByParameter}${commentParameter}${commentAmountParameter}) {
            totalCount
            startIndex
        }
    `;
	}
	return `
        comments (first: ${startAt}${maxResultsParameter}${orderByParameter}${commentParameter}${commentAmountParameter}) {
            nodes {
                id
                author {
                    accountId
                    displayName
                    avatarUrl
                }
                updateAuthor {
                    accountId
                    displayName
                    avatarUrl
                }
                ${jiraCommentVisibility}
                created
                updated
                jsdPublic
                bodyAdf
                jsdAuthorCanSeeRequest
                eventOccurredAt
                jsdIncidentActivityViewHidden
            }
            totalCount
            startIndex
        }
    `;
};
