import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import businessTimelineParameters from '@atlassian/jira-relay/src/__generated__/src_jiraHarmonisedBusinessTimelineQuery$parameters';
import timelineParameters from '@atlassian/jira-relay/src/__generated__/src_timelineQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type GetJqlOptions = {
	projectKey: string;
};

const getJql = ({ projectKey }: GetJqlOptions) => `project = '${projectKey}'`;

export const timelineEntrypoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-harmonised-business-timeline" */ './src'),
	),
	getPreloadProps: ({ context, tenantContext: { cloudId } }: EntryPointRouteParams) => {
		const namespace = 'ISSUE_NAVIGATOR';
		const projectKey = context.match.params.projectKey;

		if (projectKey == null) {
			throw new Error('Project key missing');
		}

		const fieldSetIds = ['issuetype', 'issuekey', 'summary', 'Start date[Date]', 'duedate'];

		return {
			queries: {
				businessTimelineQuery: {
					options: {
						fetchPolicy: 'store-and-network' as const,
					},
					parameters: businessTimelineParameters,
					variables: {
						cloudId,
						projectKey,
					},
				},
				timelineQuery: {
					options: {
						fetchPolicy: 'store-and-network' as const,
					},
					parameters: timelineParameters,
					variables: {
						amountOfColumns: 500,
						cloudId,
						fieldSetIds,
						fieldSetsInput: { fieldSetIds },
						filterId: null,
						first: 50,
						issueSearchInput: { jql: getJql({ projectKey }) },
						namespace,
						projectKey,
						shouldQueryFieldSetsById: false,
						shouldQueryHasChildren: true,
						shouldQueryProject: false,
						staticViewInput: {
							isGroupingEnabled: false,
						},
						viewConfigInput: { viewInput: { namespace } },
						viewId: 'list_default',
					},
				},
			},
		};
	},
});
