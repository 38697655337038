import React, { Fragment } from 'react';
import type { TriggerProps } from '@atlaskit/popup';
import Skeleton from '@atlaskit/skeleton';
import { Grid, Flex, xcss } from '@atlaskit/primitives';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { AppSwitcher as AkAppSwitcherNav4 } from '@atlassian/navigation-system/top-nav';
import { SwitchToTooltipText } from '@atlassian/switcher';

/**
 * AppSwitcherPlaceholderWithLoadingPopup is designed to show as a fallback,
 * while the real AppSwitcher component is loading.
 */
export function AppSwitcherPlaceholderWithLoadingPopup() {
	return (
		<Popup
			content={() => <SkeletonContent />}
			isOpen
			messageId="navigation-apps-topnav4-app-switcher.ui.trigger.popup.loading.popup"
			messageType="transactional"
			placement="bottom-start"
			shouldRenderToParent
			trigger={Trigger}
		/>
	);
}

function Trigger(triggerProps: TriggerProps) {
	return (
		<AkAppSwitcherNav4
			{...triggerProps}
			label={SwitchToTooltipText}
			aria-expanded={false}
			aria-haspopup
		/>
	);
}

function SkeletonContent() {
	return (
		<Grid
			xcss={containerStyles}
			rowGap="space.200"
			columnGap="space.150"
			templateColumns="32px 1fr"
		>
			<SkeletonItems />
		</Grid>
	);
}

function SkeletonItems() {
	return Array.from({ length: 5 }).map((_, index) => (
		<Fragment key={index}>
			<Skeleton width="32px" height="32px" isShimmering borderRadius="8px" />
			<Flex justifyContent="center" direction="column">
				<Skeleton width="100%" height="10px" isShimmering borderRadius="4px" />
			</Flex>
		</Fragment>
	));
}

const containerStyles = xcss({
	width: '250px',
	padding: 'space.200',
});
