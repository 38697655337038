import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { customIssueRoute } from '@atlassian/jira-router-routes-servicedesk-queue-routes/src/customIssueRoute.tsx';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout/src/index.tsx';
import { LazyIssueApp } from '@atlassian/jira-lazy-issue-app/src/LazyIssueApp.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { composeLayouts } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { newIssueViewLockInStatusResource } from '@atlassian/jira-router-resources-classic-projects/src/services/new-issue-view-lock-in-status/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { AsyncHorizontalNavQueue } from '@atlassian/jira-horizontal-nav-queue/src/async.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import {
	getQueuesResources,
	getInsightResources,
	getIssueListSsrResource,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskIsItsmProjectResource } from '@atlassian/jira-router-resources-service-desk-is-itsm-project/src/controllers/index.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { aiContextPanelResource } from '@atlassian/jira-servicedesk-ai-context-panel-resources/src/services/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import {
	CustomQueueRouteComponent,
	LazyServicedeskQueuesAgentAndIssueView,
} from './ui/CustomQueueRouteComponent.tsx';
import { QueueLoadingSkeleton } from './ui/queue/index.tsx';

// It is important to keep this in sync with the customQueueRouteEntry as
// they are rendering the exact same view. This is done to keep the state
// consistent when switching between the two routes.
export const customIssueRouteEntry: Route = createEntry(customIssueRoute, () => ({
	layout: composeLayouts(
		serviceProjectLayout,
		embeddedIssuePreloadLayoutBuilder([
			{
				params: [
					{
						key: 'issueKey',
					},
				],
			},
		]),
	),
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
		// Keep this consistent with the one in customQueueRouteEntry.tsx, so it won't rerender the queue agent view component
		// when navigating between queues and issues.
		// Rereder could cause problem:
		// 1. Issue view will lose issue navigator component
		// 2. When navigating back to queue, the queue will lose the scroll position.
		horizontal: fg('jsm_views_inside_queues_-_main_flag') ? AsyncHorizontalNavQueue : undefined,
	},
	skeleton: fg('jsm_views_inside_queues_-_main_flag') ? QueueLoadingSkeleton : undefined,
	resources: [
		...getQueuesResources(),
		...getInsightResources(),
		...getIssueListSsrResource(),
		newIssueViewLockInStatusResource,
		getConsolidationStateResource(),
		servicedeskIsItsmProjectResource,
		resourceWithCondition2(() => fg('rrr_for_context_panel_data'), aiContextPanelResource),
	],
	afterPaint: [LazyIssueApp],
	group: 'servicedesk',
	// It is important to keep this component consistent across the two routes so
	// that React doesn't unmount it when switching between the routes and the
	// full state is kept so that scroll position and loaded data is kept when
	// going back from issue details to the queues page.
	component: CustomQueueRouteComponent,
	forPaint: [LazyAtlassianNavigation, LazyServicedeskQueuesAgentAndIssueView],
}));
