/**
 * @generated SignedSource<<06d86524b2b70a6567377baa76093653>>
 * @relayHash db61dc1198ffa5c90103fa4b2fec250d
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 8fdaea689f351111bcfefd2601c3234bd86151866d9bfe7d6144c5ee16c68f5b

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentPerformanceQuery } from './srcVirtualAgentPerformanceQuery.graphql';

import checkForAiEnabledFF_provider from '@atlassian/jira-relay-provider/src/jsm-va-is-require-ai-enabled.relayprovider';
import roiTeaserM1Enabled_provider from '@atlassian/jira-relay-provider/src/jsm-va-roi-teaser-m1-enabled.relayprovider';
import vaStatusHeaderEnabled_provider from '@atlassian/jira-relay-provider/src/jsm-va-status-header.relayprovider';

const node: PreloadableConcreteRequest<srcVirtualAgentPerformanceQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "8fdaea689f351111bcfefd2601c3234bd86151866d9bfe7d6144c5ee16c68f5b",
    "metadata": {},
    "name": "srcVirtualAgentPerformanceQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaroiteaserm1enabledrelayprovider": roiTeaserM1Enabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvastatusheaderrelayprovider": vaStatusHeaderEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaisrequireaienabledrelayprovider": checkForAiEnabledFF_provider
    }
  }
};

export default node;
