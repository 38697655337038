import type { RecommendationType } from '../common/types.tsx';
import jpdRecommendation from './jpd-recommendation/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { RecommendationLoader } from './recommendation-loader';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { RecommendationSelector } from './recommendation-selector';

const createRecommendationsList = () => {
	const recommendationsList = [];

	recommendationsList.push(jpdRecommendation);

	// Add your own templates

	return recommendationsList;
};

export const recommendations: Array<RecommendationType> = createRecommendationsList();
