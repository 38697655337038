import React, { useCallback, useContext, useState } from 'react';
import { useMutation } from 'react-relay';
import { graphql } from 'relay-runtime';
import { Section, ButtonItem } from '@atlaskit/menu';
import { Text } from '@atlaskit/primitives';
import { AnnouncerV2 } from '@atlassian/jira-accessibility/src/ui/announcer-v2/index.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { FIXED_ITEM_DROPDOWN_ACTION } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import {
	SOURCE_NAME,
	CONTAINER_TYPE,
	CONTAINER_ID,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/customisation-context.tsx';
import { LEVEL_TWO } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import { SECTION_ITEM_MAP } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import { MENU_ID_ANALYTIC_EVENT_IDS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/side-nav.tsx';
import {
	useAnalyticsEvents,
	fireTrackAnalytics,
	ContextualAnalyticsData,
	DROPDOWN,
} from '@atlassian/jira-product-analytics-bridge';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge/src/utils/fire-analytics';
import type { CustomisableSidebarModal_Mutation } from '@atlassian/jira-relay/src/__generated__/CustomisableSidebarModal_Mutation.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { NavItemsContext, PeekMenuIdContext } from '../../common/controllers/context.tsx';
import type { JiraConfigurableNavigationItem, ConfigurableMenuId } from '../../common/types.tsx';
import { HideIcon } from './icons/hide/index.tsx';
import messages from './messages.tsx';
import {
	getA11YAlert,
	getA11YLabel,
	getNavItemHiddenFromSideBarFlagTitle,
	getNavItemRestoredFromSideBarFlagTitle,
} from './utils/index.tsx';

const HIDE_ROOT_MENU_ITEM_SUCCESS = 'HIDE_ROOT_MENU_ITEM_SUCCESS';
const RESTORE_ROOT_MENU_ITEM_SUCCESS = 'RESTORE_ROOT_MENU_ITEM_SUCCESS';

type HideRootMenuItemSectionProps = {
	menuId: ConfigurableMenuId;
	hasSeparator?: boolean;
	hasSection?: boolean;
};

export type OnHideRootMenuItem = () => void;
export type OnRestoreRootMenuItem = () => void;

function HideL1MenuItemSectionInner(props: HideRootMenuItemSectionProps) {
	const { menuId } = props;
	const { formatMessage } = useIntl();
	const { navItems } = useContext(NavItemsContext);
	const { peekMenuId } = useContext(PeekMenuIdContext);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const cloudID = useCloudId();
	const [isHidden, setIsHidden] = useState(false);
	const { showFlag, dismissFlag } = useFlagsService();

	const [commit] = useMutation<CustomisableSidebarModal_Mutation>(graphql`
		mutation hideL1MenuItemActionMutation($input: JiraUpdateUserNavigationConfigurationInput!) {
			jira @optIn(to: "JiraUserNavConfig") {
				updateUserNavigationConfiguration(input: $input) {
					success
					userNavigationConfiguration {
						navItems {
							menuId
							isVisible
						}
					}
				}
			}
		}
	`);

	const onSave = useCallback(
		(newNavItems: JiraConfigurableNavigationItem[]) => {
			commit({ variables: { input: { cloudID, navKey: 'NAV_SIDEBAR', navItems: newNavItems } } });

			fireTrackAnalytics(createAnalyticsEvent({}), 'sidebarNavItems changed', {
				originalNavItems: navItems,
				updatedNavItems: newNavItems,
				changedFrom: 'hideFromSidebarButton',
			});

			fireUIAnalytics(createAnalyticsEvent({}), {
				action: 'clicked',
				actionSubject: 'dropdownItem',
				actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[menuId],
				attributes: {
					level: LEVEL_TWO,
					componentType: FIXED_ITEM_DROPDOWN_ACTION,
					parentItemId: SECTION_ITEM_MAP[menuId],
					itemId: 'hideFromSidebar',
				},
			});
		},
		[cloudID, commit, createAnalyticsEvent, menuId, navItems],
	);

	const toggleNavItemVisibility = useCallback(
		({ isVisible }: { isVisible: boolean }) => {
			const newNavItems: JiraConfigurableNavigationItem[] = navItems.map((item) => {
				if (item.menuId === menuId) {
					return {
						...item,
						isVisible,
					};
				}
				return { ...item };
			});
			return newNavItems;
		},
		[menuId, navItems],
	);

	const onRestoreRootMenuItem: OnRestoreRootMenuItem = () => {
		if (navItems !== undefined) {
			const newNavItems = toggleNavItemVisibility({ isVisible: true });
			onSave(newNavItems);

			setIsHidden(false);

			showFlag({
				type: 'success',
				id: RESTORE_ROOT_MENU_ITEM_SUCCESS,
				title: getNavItemRestoredFromSideBarFlagTitle(menuId),
				isAutoDismiss: true,
				messageId:
					'navigation-apps-sidebar-nav4-sidebars-common-core.ui.hide-l1-menu-item-action.show-flag.success.restore-root-menu-item-success',
				messageType: 'transactional',
			});
		}
	};

	const onHideRootMenuItem: OnHideRootMenuItem = () => {
		if (navItems !== undefined) {
			const newNavItems = toggleNavItemVisibility({ isVisible: false });
			onSave(newNavItems);

			setIsHidden(true);

			showFlag({
				type: 'success',
				id: HIDE_ROOT_MENU_ITEM_SUCCESS,
				title: getNavItemHiddenFromSideBarFlagTitle(menuId),
				isAutoDismiss: true,
				messageId:
					'navigation-apps-sidebar-nav4-sidebars-common-core.ui.hide-l1-menu-item-action.show-flag.success.hide-root-menu-item-success',
				messageType: 'transactional',
				actions: [
					{
						content: messages.moveBackToSidebar,
						onClick: () => {
							onRestoreRootMenuItem();
							dismissFlag(HIDE_ROOT_MENU_ITEM_SUCCESS);
						},
					},
				],
			});
		}
	};

	// When the peekend menu id is the same as the menu id, the menu item is disabled because it's not possible
	// to hide an already hidden menu item. Do indicate this we need to both disable the button and set the color
	// of the icon and text appropriately.
	const isDisabled = peekMenuId === menuId;

	return (
		<>
			<AnnouncerV2 message={formatMessage(getA11YAlert(menuId))} shouldAnnounce={isHidden} />
			<ButtonItem
				iconBefore={
					<HideIcon label="" primaryColor={isDisabled ? 'color.icon.disabled' : 'color.icon'} />
				}
				onClick={onHideRootMenuItem}
				isDisabled={isDisabled}
				aria-label={formatMessage(getA11YLabel(menuId))}
			>
				<Text color={isDisabled ? 'color.text.disabled' : 'color.text'}>
					{formatMessage(messages.hideFromSidebar)}
				</Text>
			</ButtonItem>
		</>
	);
}

export function HideL1MenuItemSection(props: HideRootMenuItemSectionProps) {
	const { hasSeparator = true, hasSection = true } = props;

	return (
		<ContextualAnalyticsData
			sourceName={SOURCE_NAME}
			sourceType={DROPDOWN}
			containerId={CONTAINER_ID}
			containerType={CONTAINER_TYPE}
		>
			{hasSection ? (
				<Section hasSeparator={hasSeparator}>
					<HideL1MenuItemSectionInner {...props} />
				</Section>
			) : (
				<HideL1MenuItemSectionInner {...props} />
			)}
		</ContextualAnalyticsData>
	);
}
