import React from 'react';
import type AtlasGoalsType from '@atlassian/jira-spa-apps-goals/src/ui/index-old.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { RouteContext } from '@atlassian/react-resource-router';

/** @deprecated remove when cleaning up 'fun-1607-migrate-goals-route-to-entrypoints' */
export const LazyAlasGoalsClassic = lazyForPaint<typeof AtlasGoalsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-atlas-goals", jiraSpaEntry: "async-atlas-goals" */ '@atlassian/jira-spa-apps-goals/src/ui/index-old.tsx'
		),
	),
);

/** @deprecated remove when cleaning up 'fun-1607-migrate-goals-route-to-entrypoints' */
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ match }: RouteContext) => (
	<LazyPage
		Page={LazyAlasGoalsClassic}
		pageId={`classic-atlas-goals-${match.params.boardId || ''}`}
		shouldShowSpinner={false}
	/>
);
