import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';

export const projectsMenuDropdownRecommendationSelector = new UFOExperience(
	'projects-menu-dropdown.recommendation-selector',
	{
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	},
);

export const projectsMenuDropdownLoadJpdRecommendation = new UFOExperience(
	'projects-menu-dropdown.load-jpd-recommendation',
	{
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	},
);
