import type SecurityPageSpa from '@atlassian/jira-spa-apps-software-security/src/ui/index-old.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

/** @deprecated delete when cleaning up jira-software-security-routes-entrypoint-migration */
export const LazySecurity = lazyForPaint<typeof SecurityPageSpa>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-security", jiraSpaEntry: "async-security" */ '@atlassian/jira-spa-apps-software-security/src/ui/index-old.tsx'
		),
	),
);
