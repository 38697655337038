import { useCallback, useEffect, useRef } from 'react';
import type { EntryPointActions } from '@atlassian/jira-entry-point/src/controllers/utils/types.tsx';
import { modifyListeners } from './utils.tsx';

export type EntryPointButtonTrigger = (element: HTMLElement | null) => void;

export const useEntryPointButtonTrigger = (
	actions: EntryPointActions,
	isActive = true,
	eventMap?: Record<string, EventListener>,
): EntryPointButtonTrigger => {
	const removePreviousListeners = useRef<(() => void) | null>(null);
	const isMounted = useRef<boolean>(true);

	useEffect(
		() => () => {
			removePreviousListeners.current?.();
			removePreviousListeners.current = null;
			isMounted.current = false;
		},
		[],
	);

	return useCallback(
		(element: HTMLElement | null) => {
			if (!isMounted.current) {
				return;
			}

			removePreviousListeners.current?.();

			const elementEventMap = eventMap || getDefaultEntrypointActionEventMap(actions);

			if (element && isActive) {
				modifyListeners({ element, operationName: 'add', eventMap: elementEventMap });

				removePreviousListeners.current = () => {
					modifyListeners({ element, operationName: 'remove', eventMap: elementEventMap });
				};
			} else {
				removePreviousListeners.current = null;
			}
		},
		[actions, eventMap, isActive],
	);
};

export const getDefaultEntrypointActionEventMap = (
	actions: EntryPointActions,
): Record<string, EventListener> => ({
	click: actions.load,
	mouseenter: actions.preload,
	mouseleave: actions.cancelPreload,
	focusin: actions.preload,
	focusout: actions.cancelPreload,
});
