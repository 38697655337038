import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	projects: {
		id: 'navigation-apps-sidebar-nav4-common.common.projects',
		defaultMessage: 'Projects',
		description: 'Message for projects nav menu item in the sidebar',
	},
	filters: {
		id: 'navigation-apps-sidebar-nav4-common.common.filters',
		defaultMessage: 'Filters',
		description: 'Message for filters nav menu item in the sidebar',
	},
	dashboards: {
		id: 'navigation-apps-sidebar-nav4-common.common.dashboards',
		defaultMessage: 'Dashboards',
		description: 'Message for dashboards nav menu item in the sidebar',
	},
	overviews: {
		id: 'navigation-apps-sidebar-nav4-common.common.overviews',
		defaultMessage: 'Overviews',
		description: 'Message for overviews nav menu item in the sidebar',
	},
	teams: {
		id: 'navigation-apps-sidebar-nav4-common.common.teams',
		defaultMessage: 'Teams',
		description: 'Message for teams nav menu item in the sidebar',
	},
	assets: {
		id: 'navigation-apps-sidebar-nav4-common.common.assets',
		defaultMessage: 'Assets',
		description: 'Message for assets nav menu item in the sidebar',
	},
	plans: {
		id: 'navigation-apps-sidebar-nav4-common.common.plans',
		defaultMessage: 'Plans',
		description: 'Message for plans nav menu item in the sidebar',
	},
	apps: {
		id: 'navigation-apps-sidebar-nav4-common.common.apps',
		defaultMessage: 'Apps',
		description: 'Message for apps nav menu item in the sidebar',
	},
	operations: {
		id: 'navigation-apps-sidebar-nav4-common.common.operations',
		defaultMessage: 'Operations',
		description: 'Message for Operations nav menu item in the sidebar',
	},
	goals: {
		id: 'navigation-apps-sidebar-nav4-common.common.goals',
		defaultMessage: 'Goals',
		description: 'Message for Goals nav menu item in the sidebar',
	},
	recent: {
		id: 'navigation-apps-sidebar-nav4-common.common.recent',
		defaultMessage: 'Recent',
		description: 'Message for Recent nav menu item in the sidebar',
	},
	starred: {
		id: 'navigation-apps-sidebar-nav4-common.common.starred',
		defaultMessage: 'Starred',
		description: 'Message for Starred nav menu item in the sidebar',
	},
	roadmaps: {
		id: 'navigation-apps-sidebar-nav4-common.common.roadmaps',
		defaultMessage: 'Roadmaps',
		description: 'Message for Roadmaps nav menu item in the sidebar',
	},
	customerService: {
		id: 'navigation-apps-sidebar-nav4-common.common.customer-service',
		defaultMessage: 'Customer service',
		description: 'Message for Customer service nav menu item in the sidebar',
	},
	customerHubs: {
		id: 'navigation-apps-sidebar-nav4-common.common.customer-hubs',
		defaultMessage: 'Customer hubs',
		description: 'Message for Customer hubs nav menu item in the sidebar',
	},
	customerServiceAnalytics: {
		id: 'navigation-apps-sidebar-nav4-common.common.customerServiceAnalytics',
		defaultMessage: 'Insights',
		description: 'Message for Insights nav menu item in the sidebar',
	},
	forYou: {
		id: 'navigation-apps-sidebar-nav4-common.common.forYou',
		defaultMessage: 'For you',
		description: 'Message for For You nav menu item in the sidebar',
	},
});
