import React, { useCallback, useMemo } from 'react';
import {
	CustomProductHome as AkCustomProductHomeDI,
	ProductHome as AkProductHomeDI,
} from '@atlaskit/atlassian-navigation';
import {
	JiraIcon,
	JiraLogo,
	JiraServiceManagementLogo,
	JiraServiceManagementIcon,
	JiraProductDiscoveryLogo,
	JiraProductDiscoveryIcon,
	AtlassianIcon,
	AtlassianLogo,
	type LogoProps,
} from '@atlaskit/logo';
import {
	JiraIcon as JiraIconTemp,
	JiraLogo as JiraLogoTemp,
} from '@atlaskit/temp-nav-app-icons/jira';
import {
	JiraServiceManagementIcon as JiraServiceManagementIconTemp,
	JiraServiceManagementLogo as JiraServiceManagementLogoTemp,
} from '@atlaskit/temp-nav-app-icons/jira-service-management';
import {
	JiraProductDiscoveryIcon as JiraProductDiscoveryIconTemp,
	JiraProductDiscoveryLogo as JiraProductDiscoveryLogoTemp,
} from '@atlaskit/temp-nav-app-icons/jira-product-discovery';
import {
	CustomerServiceManagementIcon,
	CustomerServiceManagementLogo,
} from '@atlaskit/temp-nav-app-icons/customer-service-management';
import type {
	LogoProps as TempLogoProps,
	IconProps as TempIconProps,
} from '@atlaskit/temp-nav-app-icons/types';
import { Box } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { useIntl } from '@atlassian/jira-intl';
import { testIdGenerate } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import type { ProductName } from '@atlassian/jira-shared-types/src/product-name.tsx';
import { useProductName as useProductNameDI } from '@atlassian/jira-tenant-context-controller/src/components/product-name/index.tsx';
import { NavLogo } from '@atlassian/navigation-system';
import { useRouterActions as useRouterActionsDI } from '@atlassian/react-resource-router';
import { NAVIGATION_ITEM_ID } from '../../common/constants.tsx';
import { TopLevelErrorBoundary } from '../../common/ui/error-boundary/index.tsx';
import {
	useCustomLogo as useCustomLogoDI,
	useCustomIcon as useCustomIconDI,
} from '../../controllers/custom-logo/index.tsx';
import { useNavigationItemAnalytics as useNavigationItemAnalyticsDI } from '../../controllers/navigation-item-analytics/main.tsx';
import { useSiteTitle as useSiteTitleDI } from '../../controllers/site-title/index.tsx';
import messages from './messages.tsx';
import type { ProductHomeProps } from './types.tsx';

export const iconMap = {
	jira: { icon: JiraIcon, logo: JiraLogo },
	serviceDesk: { icon: JiraServiceManagementIcon, logo: JiraServiceManagementLogo },
	'product-discovery': {
		icon: JiraProductDiscoveryIcon,
		logo: JiraProductDiscoveryLogo,
	},
	'customer-service': {
		icon: AtlassianIcon,
		logo: AtlassianLogo,
	},
} as const;

export const iconMapTemp = {
	jira: { icon: JiraIconTemp, logo: JiraLogoTemp },
	serviceDesk: { icon: JiraServiceManagementIconTemp, logo: JiraServiceManagementLogoTemp },
	'product-discovery': {
		icon: JiraProductDiscoveryIconTemp,
		logo: JiraProductDiscoveryLogoTemp,
	},
	'customer-service': {
		icon: CustomerServiceManagementIcon,
		logo: CustomerServiceManagementLogo,
	},
} as const;

const ProductHome = componentWithCondition(() => getWillShowNav4(), ProductHomeNew, ProductHomeOld);

const ProductHomeWithErrorBoundary = (props: ProductHomeProps) => (
	<TopLevelErrorBoundary id={NAVIGATION_ITEM_ID.HOME}>
		<ProductHome {...props} />
	</TopLevelErrorBoundary>
);

export { ProductHomeWithErrorBoundary as ProductHome };

function ProductHomeOld({
	AkCustomProductHome = AkCustomProductHomeDI,
	AkProductHome = AkProductHomeDI,
	useCustomLogo = useCustomLogoDI,
	useNavigationItemAnalytics = useNavigationItemAnalyticsDI,
	useProductName = useProductNameDI,
	useRouterActions = useRouterActionsDI,
	useSiteTitle = useSiteTitleDI,
}: ProductHomeProps) {
	const testIdPrefix = testIdGenerate('product-home-');
	const logoUrl = useCustomLogo();
	const productName = useProductName();
	const siteTitleData = useSiteTitle();
	const siteTitle = (siteTitleData.shouldShow && siteTitleData.value) || undefined;

	const triggerAnalytics = useNavigationItemAnalytics({
		navigationItemId: NAVIGATION_ITEM_ID.LOGO,
	});

	const { formatMessage } = useIntl();

	const { push } = useRouterActions();
	const homeUrl = '/jira';
	const navigateToHome = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'ev' implicitly has an 'any' type.
		(ev) => {
			triggerAnalytics();
			if (ev.ctrlKey || ev.metaKey) return;
			ev.preventDefault();
			push(homeUrl);
		},
		[triggerAnalytics, push, homeUrl],
	);

	if (logoUrl) {
		return (
			<AkCustomProductHome
				iconUrl={logoUrl}
				logoUrl={logoUrl}
				logoAlt={formatMessage(messages.customLogoAltText)}
				iconAlt={formatMessage(messages.customIconAltText)}
				href={homeUrl}
				onClick={navigateToHome}
				siteTitle={siteTitle}
				testId={testIdPrefix}
			/>
		);
	}

	const label = getLogoLabel(formatMessage, productName);
	const { icon: Icon, logo: Logo } = getProductIconAndLogo(productName, undefined, label);
	return (
		<AkProductHome
			icon={Icon}
			logo={Logo}
			href={homeUrl}
			onClick={navigateToHome}
			siteTitle={siteTitle}
			testId={testIdPrefix}
		/>
	);
}

function ProductHomeNew({
	useCustomLogo = useCustomLogoDI,
	useCustomIcon = useCustomIconDI,
	useNavigationItemAnalytics = useNavigationItemAnalyticsDI,
	useProductName = useProductNameDI,
	useRouterActions = useRouterActionsDI,
}: ProductHomeProps) {
	const testIdPrefix = testIdGenerate('product-home-');
	const logoUrl = useCustomLogo();
	const iconUrl = useCustomIcon();
	const productName = useProductName();
	const triggerAnalytics = useNavigationItemAnalytics({
		navigationItemId: NAVIGATION_ITEM_ID.LOGO,
	});
	const { formatMessage } = useIntl();
	const { push } = useRouterActions();
	const homeUrl = '/jira';
	const navigateToHome = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'ev' implicitly has an 'any' type.
		(ev) => {
			triggerAnalytics();
			if (ev.ctrlKey || ev.metaKey) return;
			ev.preventDefault();
			push(homeUrl);
		},
		[triggerAnalytics, push, homeUrl],
	);
	const { icon: ProductIcon, logo: ProductLogo } = getProductIconAndLogo(productName, testIdPrefix);

	const Logo = useMemo(
		() =>
			logoUrl
				? () => (
						<CustomImgNav4
							alt={formatMessage(messages.customLogoAltText)}
							src={logoUrl}
							testId={`${testIdPrefix}-logo`}
						/>
					)
				: ProductLogo,
		[logoUrl, ProductLogo, formatMessage, testIdPrefix],
	);

	const Icon = useMemo(
		() =>
			logoUrl && iconUrl
				? () => (
						<CustomImgNav4
							alt={formatMessage(messages.customIconAltText)}
							src={iconUrl}
							testId={`${testIdPrefix}-icon`}
						/>
					)
				: ProductIcon,
		[logoUrl, iconUrl, ProductIcon, testIdPrefix, formatMessage],
	);

	const label = getLogoLabel(formatMessage, productName);
	return (
		<Box testId={`${testIdPrefix}-container`}>
			<NavLogo label={label} icon={Icon} logo={Logo} href={homeUrl} onClick={navigateToHome} />
		</Box>
	);
}

// formatMessage is intentionally an any type due to absence of easily importable type, and because actual
// type definition contains any as well!
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getLogoLabel(formatMessage: any, productName: ProductName): string {
	let label = '';
	if (productName === 'serviceDesk') {
		label = formatMessage(messages.jsmLogoLabel);
	} else if (productName === 'product-discovery') {
		label = formatMessage(messages.jpdLogoLabel);
	} else if (productName === 'customer-service') {
		label = formatMessage(messages.jcsLogoLabel);
	} else {
		label = formatMessage(messages.jiraLogoLabel);
	}
	return label;
}

/**
 * Due to typescript limitations (props type clashes), we need to handle the icon and logo components differently for the
 * new and old navigation apps. This function returns the appropriate components based on the product name.
 */
function getProductIconAndLogo(productName: ProductName, testIdPrefix?: string, label?: string) {
	const productKey = productName === 'core' || productName === 'software' ? 'jira' : productName;

	const { icon: OldIconComponent, logo: OldLogoComponent } = iconMap[productKey];
	const { icon: NewIconComponent, logo: NewLogoComponent } = iconMapTemp[productKey];

	const createComponent =
		<P extends LogoProps | TempIconProps | TempLogoProps>(
			Component: React.ComponentType<P>,
			type: 'icon' | 'logo',
		) =>
		(props: P) => {
			const additionalProps: Partial<P> = {};
			// Nav v3 sets a testId, Nav v4 sets a label
			if (testIdPrefix) {
				additionalProps.testId = `${testIdPrefix}-${type}`;
			}
			if (label) {
				additionalProps.label = label;
			}
			// Casting required due to complexities of the different component prop types
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return <Component {...props} {...(additionalProps as P)} />;
		};

	const oldIcon = createComponent<LogoProps>(OldIconComponent, 'icon');
	const oldLogo = createComponent<LogoProps>(OldLogoComponent, 'logo');
	const newIcon = createComponent<TempIconProps>(NewIconComponent, 'icon');
	const newLogo = createComponent<TempLogoProps>(NewLogoComponent, 'logo');

	return fg('platform-team25-app-icon-tiles')
		? { icon: newIcon, logo: newLogo }
		: { icon: oldIcon, logo: oldLogo };
}

type CustomImgProps = {
	alt: string;
	src: string;
	testId: string;
};

function CustomImgNav4({ alt, src, testId }: CustomImgProps) {
	return <img src={src} alt={alt} data-testid={testId} />;
}
