import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = {
	isOpen: boolean;
	onClose(): void;
	onOpen(): void;
};

export const AppSwitcherIntegrationEntrypoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-topnav4-app-switcher-entrypoint" */ './async.tsx'),
	),
	getPreloadProps: (params: EntryPointParams) => ({ extraProps: params }),
});
