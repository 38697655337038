import camelCase from 'lodash/camelCase';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarNextGen, {
	ForPaintSoftwareProjectSidebar as AsyncForPaintSidebarNextGen,
} from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { createLayout } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { softwareInboxNextGenRoute } from '@atlassian/jira-router-routes-software-inbox-routes/src/softwareInboxNextGenRoute.tsx';
import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { softwareProjectLayoutNoOnboarding } from '@atlassian/jira-software-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { ProjectIssueNavigatorSkeleton } from '@atlassian/jira-skeletons/src/ui/issue-navigator/components/project-app.tsx';
import { notFoundPageEntrypoint } from '@atlassian/jira-router-routes-not-found-entrypoint/src/ui/error-pages/not-found/index.tsx';
import { getCommonProjectResources } from '@atlassian/jira-router-routes-issue-navigator-entries/src/common/getCommonProjectResources.tsx';
import { inboxPageEntryPoint } from './ui/index.tsx';

export const softwareInboxNextGenRouteEntry: Route = createEntry(softwareInboxNextGenRoute, () => ({
	group: 'software',
	meta: {
		reporting: {
			id: 'software-inbox-classic',
			packageName: camelCase('software-inbox-classic'),
			teamName: 'boysenberry',
		},
	},
	skeleton: ProjectIssueNavigatorSkeleton,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: componentWithFG(
			'make_nav3_sidebar_lazyforpaint',
			AsyncForPaintSidebarNextGen,
			AsyncSidebarNextGen,
		),
		horizontal: AsyncHorizontalNavJira,
		onlyShowHorizontalOnNav4: true,
	},
	resources: [...getCommonProjectResources()],
	layout: createLayout(softwareProjectLayoutNoOnboarding),
	// We want to opt out of concurrent mode for all NIN routes https://hello.atlassian.net/wiki/x/wTRfDAE
	// But allow for users with empanada-concurrent-incremental gate enabled to continue testing it
	shouldOptOutConcurrentMode:
		fg('jira-concurrent-incremental') && !fg('empanada-concurrent-incremental'),
	entryPoint: () =>
		fg('jcs_developer_escalations') ? inboxPageEntryPoint : notFoundPageEntrypoint,
}));
