/**
 * @generated SignedSource<<9dcfa2288b017114bb729c1b9924bc2f>>
 * @relayHash 35bf80af10f761534a866198d211e694
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 638fc9ef6f320a1f0ec18ec1dc3f623acfca56ab8ab242d622172bf45270a14e

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessBoardEmbedQuery } from './src_jiraBusinessBoardEmbedQuery.graphql';

import withCategoryField_provider from '@atlassian/jira-relay-provider/src/relay-category-field.relayprovider';

const node: PreloadableConcreteRequest<src_jiraBusinessBoardEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "638fc9ef6f320a1f0ec18ec1dc3f623acfca56ab8ab242d622172bf45270a14e",
    "metadata": {},
    "name": "src_jiraBusinessBoardEmbedQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaycategoryfieldrelayprovider": withCategoryField_provider
    }
  }
};

export default node;
