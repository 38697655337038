import React, { type ReactNode } from 'react';
import isEqual from 'lodash/isEqual';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createContainer, createHook, createStore } from '@atlassian/react-sweet-state';
import type { IrremovableFields } from '../../utils/irremovable-fields/index.tsx';
import { useClauseMap } from '../ast/index.tsx';
import type { Store, Props } from './types.tsx';
import { getClausesData } from './utils.tsx';

type Actions = {};
const actions = Object.freeze<Actions>({});

const SearchStateContainerInternal = createContainer<Props>();

const store = createStore<Store, Actions, Props>({
	containedBy: SearchStateContainerInternal,
	initialState: {
		astClauseOrder: [],
		clausesByFieldId: {},
		secondaryClausesByFieldId: {},
	},
	actions,
	name: 'JQLBasic-SearchStateService',
	handlers: {
		onInit:
			() =>
			({ setState }, props) => {
				setState(getClausesData(props));
			},

		onContainerUpdate:
			(nextProps, prevProps) =>
			({ setState }) => {
				if (!isEqual(nextProps, prevProps)) {
					setState(getClausesData(nextProps));
				}
			},
	},
});

export type WrapperProps = {
	excludedFields?: string[];
	irremovableFields: IrremovableFields;
	children: ReactNode;
};

const DEFAULT_EXCLUDED_FIELDS: string[] = [];

export const SearchStateContainer = ({
	excludedFields = DEFAULT_EXCLUDED_FIELDS,
	irremovableFields,
	children,
}: WrapperProps) => {
	const [clauseMap] = useClauseMap();

	return (
		<SearchStateContainerInternal
			clauseMap={clauseMap}
			excludedFields={excludedFields}
			irremovableFields={irremovableFields}
		>
			{children}
		</SearchStateContainerInternal>
	);
};

export const useSearchState = createHook(store);
