import { useCallback } from 'react';
import { useOverflowStatus } from '@atlaskit/atlassian-navigation';
import {
	useAnalyticsEvents,
	fireUIAnalytics,
	type Attributes as AnalyticsAttributes,
} from '@atlassian/jira-product-analytics-bridge';
import type { UseNavigationItemAnalyticsType, UseNavigationItemAnalyticsProps } from './types.tsx';

export function useNavigationItemAnalytics({
	navigationItemId,
}: UseNavigationItemAnalyticsProps): UseNavigationItemAnalyticsType {
	const { isVisible } = useOverflowStatus();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return useCallback(
		(attributes = {}) => {
			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'navigationItem',
			});

			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			fireUIAnalytics(analyticsEvent, navigationItemId, {
				// @ts-expect-error - TS2698 - Spread types may only be created from object types.
				...attributes,
				isInMore: !isVisible,
				navigationLayer: 'global',
			} as AnalyticsAttributes);
		},
		[createAnalyticsEvent, isVisible, navigationItemId],
	);
}
