import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { AsyncKbNav } from '@atlassian/jira-horizontal-nav-jsm-knowledge-base/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskNavigationResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { virtualAgentKnowledgebaseTouchpointResource } from '@atlassian/jira-router-resources-service-desk-virtual-agent/src/knowledge-base-touchpoint/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { knowledgeHubBrowseAllArticlesRoute } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-routes/src/knowledgeHubBrowseAllArticlesRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import {
	APP_NAMES,
	toPackageName,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import { isFedRamp } from '@atlassian/atl-context';
import { getKnowledgeHubResources } from './common/getKnowledgeHubResources.tsx';
import ServiceDeskKnowledgeHubBrowseAll, {
	LazyServicedeskKnowledgeHubBrowseAllArticles,
} from './ui/browse-all/index.tsx';

export const knowledgeHubBrowseAllArticlesRouteEntry = createEntry(
	knowledgeHubBrowseAllArticlesRoute,
	() => ({
		group: 'servicedesk',
		component: ServiceDeskKnowledgeHubBrowseAll,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			horizontal: AsyncKbNav,
			// TODO: Remove this during jira_nav4 cleanup
			onlyShowHorizontalOnNav4: true,
		},
		resources: [
			...getServiceDeskNavigationResources(),
			...getKnowledgeHubResources(),
			getConsolidationStateResource(),
			resourceWithCondition2(() => !isFedRamp(), virtualAgentKnowledgebaseTouchpointResource),
		],
		forPaint: [LazyAtlassianNavigation, LazyServicedeskKnowledgeHubBrowseAllArticles],
		meta: {
			reporting: {
				id: APP_NAMES.KB_BROWSE_ARTICLES,
				packageName: toPackageName(APP_NAMES.KB_BROWSE_ARTICLES),
				teamName: 'jsm-nebula',
			},
		},
	}),
);
