import { useCallback, useEffect, useState, useRef } from 'react';
import {
	EMBEDDED_CONFLUENCE_MODE,
	type EmbeddedContentModalProps,
	type OnEmbeddedContentAction,
} from '@atlassian/embedded-confluence';
import { useEmbeddedConfluenceSidePanel } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-embedded-confluence-side-panel/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagService, toFlagId } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import {
	useAnalyticsEvents,
	type UIAnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import {
	LINK_PAGE_SUCCESS_FLAG_ID,
	PUBLISH_DRAFT_SUCCESS_FLAG_ID,
	LINK_PAGE_FAILED_FLAG_ID,
	DEFER_SPACE_PUBLISH_DRAFT_SUCCESS_FLAG_ID,
} from '../../common/constants/embedded-pages.tsx';
import { convertToViewPageUrl } from '../../common/utils/index.tsx';
import { useEmbeddedPageTracking } from '../use-embedded-page-tracking/index.tsx';
import messages from './messages.tsx';

export const EVENT_SUCCESS = 'success';
export const OPERATIONAL_EVENT_TYPE = 'operational';

export const useEmbeddedPageDraft = ({
	isLive,
	embeddedConfluenceSource,
	onClose,
	url,
	onEmbeddedContentAction,
	linkConfluencePage,
}: {
	url: string;
	isLive?: boolean;
	embeddedConfluenceSource?: string;
	onEmbeddedContentAction?: OnEmbeddedContentAction;
	onClose: EmbeddedContentModalProps['onClose'];
	linkConfluencePage?: (link: string, analyticsEvent: UIAnalyticsEvent) => void;
}) => {
	const [
		{
			panel: { isOpen },
		},
		actions,
	] = useEmbeddedConfluenceSidePanel();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isContinueDraftOpen, setIsContinueDraftOpen] = useState(false);
	const [isPublished, setIsPublished] = useState(false);
	const [epMode, setEpMode] = useState<EMBEDDED_CONFLUENCE_MODE>(
		isLive ? EMBEDDED_CONFLUENCE_MODE.VIEW_MODE : EMBEDDED_CONFLUENCE_MODE.EDIT_MODE,
	);
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagService();
	const fireAnalyticsEvent = useEmbeddedPageTracking();
	const deferSpaceToast = ff('toast_defer_space_ntufi');
	const [
		{
			confluenceData: { deferredSpaceName },
		},
	] = useEmbeddedConfluenceSidePanel();
	const handledLiveDocUrls = useRef(new Set<string>());

	useEffect(() => {
		if (deferSpaceToast && deferredSpaceName && isPublished) {
			showFlag({
				key: toFlagId(DEFER_SPACE_PUBLISH_DRAFT_SUCCESS_FLAG_ID),
				type: 'success',
				title: formatMessage(messages.publishPageSuccessTitle),
				description: formatMessage(messages.deferSpacePublishPageSuccessDescription, {
					spaceName: deferredSpaceName,
				}),
				messageId:
					'issue-create-confluence-content.controllers.use-embedded-page-draft.show-flag.success.defer-space-publish-draft-success-flag',
				messageType: 'transactional',
			});
		}
	}, [formatMessage, isPublished, deferSpaceToast, deferredSpaceName, showFlag]);

	const openContinueDraftModal = useCallback(() => {
		if (!isPublished) {
			setIsContinueDraftOpen(true);
			fireAnalyticsEvent({
				action: 'clicked',
				eventName: isLive ? 'closeLiveDoc' : 'closeUnpublishedDraft',
				embeddedConfluenceSource,
			});
		} else {
			onClose && onClose();
		}
	}, [embeddedConfluenceSource, fireAnalyticsEvent, isLive, isPublished, onClose]);

	const closeContinueDraftModal = () => {
		setIsContinueDraftOpen(false);
	};
	const closeAll = () => {
		closeContinueDraftModal();
		onClose && onClose();
	};
	const handleEmbeddedContentAction: OnEmbeddedContentAction = useCallback(
		(action, name) => {
			const linkingSuccessDescriptionMessage = () => {
				if (fg('jira-issue-terminology-refresh-m3')) {
					return isLive
						? messages.linkLiveDocSuccessDescriptionIssueTermRefresh
						: messages.publishAndLinkPageSuccessDescriptionIssueTermRefresh;
				}
				return isLive
					? messages.linkLiveDocSuccessDescription
					: messages.publishAndLinkPageSuccessDescription;
			};
			if (name === 'edit-page/close') {
				if (isPublished) {
					setEpMode(EMBEDDED_CONFLUENCE_MODE.VIEW_MODE);
					if (isOpen && fg('jira_issue_view_expand_modeless_ep')) {
						actions.setConfluenceData({
							contentUrl: url,
							mode: EMBEDDED_CONFLUENCE_MODE.VIEW_MODE,
						});
					}
				} else {
					openContinueDraftModal();
				}
			}
			if (name === 'edit-page/load' && action === 'taskSuccess') {
				if (epMode === EMBEDDED_CONFLUENCE_MODE.VIEW_MODE) {
					setEpMode(EMBEDDED_CONFLUENCE_MODE.EDIT_MODE);
					if (isOpen && fg('jira_issue_view_expand_modeless_ep')) {
						actions.setConfluenceData({
							contentUrl: url,
							mode: EMBEDDED_CONFLUENCE_MODE.EDIT_MODE,
						});
					}
				}
			}
			if (name === 'edit-page/publish' && action === 'taskSuccess') {
				if (isOpen && fg('jira_issue_view_expand_modeless_ep')) {
					actions.setConfluenceData({
						contentUrl: url,
						isDraft: false,
						mode: EMBEDDED_CONFLUENCE_MODE.VIEW_MODE,
					});
				}

				fireAnalyticsEvent({
					action: EVENT_SUCCESS,
					eventName: isLive ? 'createLiveDoc' : 'publishDraftPage',
					embeddedConfluenceSource,
					eventType: OPERATIONAL_EVENT_TYPE,
				});
				const analyticsEvent = createAnalyticsEvent({
					action: 'clicked',
					actionSubject: 'button',
				});

				if (isPublished && !isLive) {
					showFlag({
						key: toFlagId(PUBLISH_DRAFT_SUCCESS_FLAG_ID),
						type: 'success',
						title: formatMessage(messages.publishPageSuccessTitle),
						description: formatMessage(messages.publishPageSuccessDescription),
						messageId:
							'issue-create-confluence-content.controllers.use-embedded-page-draft.show-flag.success.publish-draft-success-flag',
						messageType: 'transactional',
					});
				} else {
					setIsPublished(true);
					if (isOpen && fg('jira_issue_view_expand_modeless_ep')) {
						actions.setConfluenceData({
							contentUrl: url,
							isDraft: false,
						});
					}
					// TODO this is a temporary solution, remove when https://hello.atlassian.net/jira/software/projects/CBT/boards/4465?selectedIssue=CBT-2895 is complete
					setTimeout(() => {
						try {
							if (linkConfluencePage) {
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								linkConfluencePage(convertToViewPageUrl(url)!, analyticsEvent);
								fireAnalyticsEvent({
									action: EVENT_SUCCESS,
									eventName: isLive ? 'linkConfluenceLiveDoc' : 'linkConfluencePage',
									embeddedConfluenceSource,
									eventType: OPERATIONAL_EVENT_TYPE,
								});
								if (!deferSpaceToast) {
									showFlag({
										key: toFlagId(LINK_PAGE_SUCCESS_FLAG_ID),
										type: 'success',
										title: formatMessage(
											isLive
												? messages.linkLiveDocSuccessTitle
												: messages.publishAndLinkPageSuccessTitle,
										),
										description: formatMessage(linkingSuccessDescriptionMessage()),
										messageId:
											'issue-create-confluence-content.controllers.use-embedded-page-draft.show-flag.success.link-page-success-flag',
										messageType: 'transactional',
									});
								}
							}
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
						} catch (error: any) {
							fireErrorAnalytics({
								meta: {
									id: isLive ? 'linkConfluenceLiveDoc' : 'linkConfluencePage',
									teamName: 'confluence-better-together',
								},
								attributes: { embeddedConfluenceSource },
								error,
							});
							showFlag({
								key: toFlagId(LINK_PAGE_FAILED_FLAG_ID),
								type: 'error',
								title: formatMessage(messages.linkPageFailedTitle),
								description: formatMessage(messages.linkPageFailedDescription),
								messageId:
									'issue-create-confluence-content.controllers.use-embedded-page-draft.show-flag.error.link-page-failed-flag',
								messageType: 'transactional',
							});
						}
					}, 3500);
				}
			}
			if (name === 'edit-page/publish' && action === 'taskFail') {
				fireErrorAnalytics({
					meta: {
						id: isLive ? 'createLiveDoc' : 'publishDraftPage',
						teamName: 'confluence-better-together',
					},
					attributes: { embeddedConfluenceSource },
					error: new Error(`Error publishing draft page: {name: ${name}, action: ${action}}`),
				});
			}
			onEmbeddedContentAction && onEmbeddedContentAction(action, name);
		},
		[
			createAnalyticsEvent,
			embeddedConfluenceSource,
			epMode,
			fireAnalyticsEvent,
			formatMessage,
			isPublished,
			deferSpaceToast,
			linkConfluencePage,
			onEmbeddedContentAction,
			openContinueDraftModal,
			showFlag,
			url,
			actions,
			isOpen,
			isLive,
		],
	);

	// This is a little weird, but to avoid duplicating the code above, if the page is live and state local state is !isPublished,
	// we simulate the action of publishing from inside the ep modal. This in turn displays the view page, link the page to the issue
	// and show the flag about the page being published and linked.
	useEffect(() => {
		if (
			isLive &&
			embeddedConfluenceSource?.endsWith('Panel') &&
			!handledLiveDocUrls.current.has(url)
		) {
			handledLiveDocUrls.current.add(url);
			if (handledLiveDocUrls.current.has(url)) {
				handleEmbeddedContentAction('taskSuccess', 'edit-page/publish');
			} else {
				handleEmbeddedContentAction('taskFail', 'edit-page/publish');
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLive, url]);

	// in the case that the user creates a live doc and then wants to create a page, we must ensure we open the draft for the page in edit mode
	useEffect(() => {
		setEpMode(isLive ? EMBEDDED_CONFLUENCE_MODE.VIEW_MODE : EMBEDDED_CONFLUENCE_MODE.EDIT_MODE);
	}, [isLive]);

	return {
		isContinueDraftOpen,
		openContinueDraftModal,
		closeContinueDraftModal,
		closeAll,
		handleEmbeddedContentAction,
		fireAnalyticsEvent,
		epMode,
	};
};
