import React from 'react';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { TopLevelErrorBoundary } from '@atlassian/jira-navigation-apps-topnav4-common/src/ui/error-boundary/index.tsx';
import { AppSwitcherTrigger } from './trigger/index.tsx';

export function AppSwitcher() {
	return (
		<TopLevelErrorBoundary id={MENU_ID.APP_SWITCHER}>
			<AppSwitcherTrigger />
		</TopLevelErrorBoundary>
	);
}
