import React, { useMemo, type ReactNode } from 'react';

import { ButtonItem } from '@atlaskit/menu';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';

import { useTracedUrl } from '../../services/use-traced-url.tsx';
import { standardToPremiumModalEntryPoint } from './entrypoint.tsx';

export type StandardToPremiumModalEntrypointTriggerProps = {
	children: ReactNode;
	product: string;
	experienceUrl?: string;
	learnMoreUrl?: string;
	onClick?: () => void;
};

export const StandardToPremiumModalEntrypointTrigger = ({
	children,
	product,
	experienceUrl,
	learnMoreUrl,
	onClick,
}: StandardToPremiumModalEntrypointTriggerProps) => {
	const { url: learnMoreHref = '' } = useTracedUrl(learnMoreUrl ?? '/admin/billing');
	// admin/billing redirects to the subscription page
	const { url: changeOfferingHref = '' } = useTracedUrl(experienceUrl ?? '/admin/billing');

	const entryPointProps = useMemo(
		() => ({
			product,
			learnMoreHref,
			changeOfferingHref,
		}),
		[product, learnMoreHref, changeOfferingHref],
	);

	return (
		<ModalEntryPointPressableTrigger
			entryPoint={standardToPremiumModalEntryPoint}
			entryPointProps={entryPointProps}
			interactionName="standard-to-premium-modal"
			useInternalModal
			modalProps={{
				width: 'x-large',
				shouldCloseOnOverlayClick: true,
				shouldCloseOnEscapePress: true,
			}}
		>
			{({ ref }) => (
				<ButtonItem onClick={onClick} ref={ref}>
					{children}
				</ButtonItem>
			)}
		</ModalEntryPointPressableTrigger>
	);
};
