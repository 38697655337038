import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { expVal, expValEquals } from '@atlassian/jira-feature-experiments';

type ExperimentM2Options = 'control' | 'blurry-background' | 'dominant-color';

// The software theming project will have a hard dependency on Nav4. As such we don't want to fire exposures or change any UX when Nav4 is not present
export const getIsSoftwareThemingEnabled = () =>
	getWillShowNav4() && expVal('jira_theming_milestone_1', 'isEnabled', false);

export const getIsSoftwareThemingM2Enabled = (variant?: ExperimentM2Options) => {
	// being part of M1 experiment is a prerequisite to be in M2
	if (!getIsSoftwareThemingEnabled()) {
		return false;
	}

	if (variant === 'blurry-background') {
		return expValEquals<ExperimentM2Options>(
			'jira_theming_milestone_2',
			'variant',
			'blurry-background',
		);
	}

	if (variant === 'dominant-color') {
		return expValEquals<ExperimentM2Options>(
			'jira_theming_milestone_2',
			'variant',
			'dominant-color',
		);
	}

	// if we're here, variant doesn't matter anymore, we just show the experiment
	return (
		expVal<ExperimentM2Options>('jira_theming_milestone_2', 'variant', 'control') !== 'control'
	);
};
