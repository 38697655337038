import React from 'react';
import type ServicedeskQueuesAgentAndIssueViewType from '@atlassian/jira-spa-apps-servicedesk-queues-agent-and-issue-view/src/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { RouteContext } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';

export const LazyServicedeskQueuesAgentAndIssueView = lazyForPaint<
	typeof ServicedeskQueuesAgentAndIssueViewType
>(() => {
	return markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-servicedesk-queues-agent-and-issue-view", jiraSpaEntry: "async-servicedesk-queues-agent-and-issue-view" */ '@atlassian/jira-spa-apps-servicedesk-queues-agent-and-issue-view'
		),
	);
});

type Props = RouteContext;

export const CustomQueueRouteComponent = ({ match }: Props) => {
	const queueId = (match.params && match.params.queueId) || '';
	const issueKey = (match.params && match.params.issueKey) || '';
	const oldIssueView = `/${(match.query && match.query.oldIssueView) || ''}`;

	return (
		<LazyPage
			Page={LazyServicedeskQueuesAgentAndIssueView}
			pageId={`spa-apps/servicedesk/queues/${queueId}/${issueKey}/${oldIssueView}`}
			shouldShowSpinner={!fg('jsm_views_inside_queues_-_main_flag')}
		/>
	);
};
