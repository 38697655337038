export const SOFTWARE_CONTOURED_THEME_ROUTES = [
	'issue',
	'software-backlog',
	'rapidboard-backlog',
	'software-timeline',
	'software-timeline-classic',
	'software-issue-navigator',
	'issue-navigator-classic',
];

// delete this on jira_theming_milestone_2 cleanup
export const SOFTWARE_FULL_THEME_ROUTES = [
	'software-boards',
	'rapidboard-board',
	'software-calendar',
	'software-calendar-classic',
	'software-list',
	'software-list-classic',
	'software-summary',
	'software-summary-classic',
];

export const SOFTWARE_FULL_THEME_ROUTES_M2 = [
	'software-boards',
	'rapidboard-board',
	'software-calendar',
	'software-calendar-classic',
	'software-list',
	'software-list-classic',
	'software-summary',
	'software-summary-classic',
	'software-backlog',
	'rapidboard-backlog',
];

// delete this on jira_theming_milestone_2 cleanup
export const SOFTWARE_THEME_ROUTES = [
	...SOFTWARE_CONTOURED_THEME_ROUTES,
	...SOFTWARE_FULL_THEME_ROUTES,
];

export const SOFTWARE_THEME_ROUTES_M2 = [
	...SOFTWARE_CONTOURED_THEME_ROUTES,
	...SOFTWARE_FULL_THEME_ROUTES_M2,
];
