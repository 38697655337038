import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	moreButtonOld: {
		id: 'jql-builder-basic.common.more-button-old',
		defaultMessage: 'More',
		description:
			'Dropdown button text which displays additional refinements that can be added to the refinement bar when clicked',
	},
	moreButton: {
		id: 'jql-builder-basic.common.more-button',
		defaultMessage: 'More filters',
		description:
			'Dropdown button text which displays additional refinements that can be added to the refinement bar when clicked',
	},
	refinementSearch: {
		id: 'jql-builder-basic.jql-editor.refinement-search',
		defaultMessage: 'Search issues',
		description: 'The label of the filtering field for arbitary values.',
	},
	searchCurrentPage: {
		id: 'jql-builder-basic.common.search-current-page',
		defaultMessage: 'Search on current page',
		description: 'Command in the command palette to trigger the page search',
	},
	refinementSearchIssueTermRefresh: {
		id: 'jql-builder-basic.common.refinement-search-issue-term-refresh',
		defaultMessage: 'Search work',
		description: 'The label of the filtering field for arbitary values.',
	},
	moreButtonLabel: {
		id: 'jql-builder-basic.common.more-button-label',
		defaultMessage: 'More filter options',
		description:
			'Dropdown button text which displays additional refinements that can be added to the refinement bar when clicked',
	},
	searchMoreFiltersPlaceholder: {
		id: 'jql-builder-basic-picker.common.search-more-filters-placeholder',
		defaultMessage: 'Search more filters',
		description:
			'Placeholder text in search input indicating that the user can search for more filters',
	},
});
export default messages;
