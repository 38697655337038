/**
 * @generated SignedSource<<baac69182035d1a9d38861dd5d793033>>
 * @relayHash ee99065d255942afed83fbaaff08e786
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID aa0a8f692a8a6cd34defacbe0f4a7a12e0af0315d0f5d9426a371611aaf7a1bf

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessBoardQuery } from './src_jiraBusinessBoardQuery.graphql';

import withCategoryField_provider from '@atlassian/jira-relay-provider/src/relay-category-field.relayprovider';

const node: PreloadableConcreteRequest<src_jiraBusinessBoardQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "aa0a8f692a8a6cd34defacbe0f4a7a12e0af0315d0f5d9426a371611aaf7a1bf",
    "metadata": {},
    "name": "src_jiraBusinessBoardQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaycategoryfieldrelayprovider": withCategoryField_provider
    }
  }
};

export default node;
