import React, { Fragment, forwardRef, type PropsWithChildren, type Ref } from 'react';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { AppSwitcher as AkAppSwitcherNav4 } from '@atlassian/navigation-system/top-nav';
import { SwitchToTooltipText } from '@atlassian/switcher';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';

type Props = {
	onOpen?(): void;
	onMouseEnter?(): void;
};

/**
 * AppSwitcherPlaceholderButton is designed to only show until the user has clicked it.
 * Then, it should hide itself forever.
 *
 * Motivation: This button acts as a placeholder for when the actual AppSwitcher
 * is loaded. We don't want to load the actual AppSwitcher until it's needed,
 * as it is large. So, the parent component shows this component until such time
 * as the user clicks on it. Then, the real AppSwitcher component is loaded, and
 * replaces this one.
 */
export const AppSwitcherPlaceholderButton = forwardRef(AppSwitcherPBWithRef);

function AppSwitcherPBWithRef({ onOpen, onMouseEnter }: Props, ref: Ref<HTMLButtonElement>) {
	if (__SERVER__) {
		return <AkAppSwitcherNav4 label={SwitchToTooltipText} ref={ref} />;
	}

	return (
		<AppSwitcherPlaceholderButtonForBrowser ref={ref} onOpen={onOpen} onMouseEnter={onMouseEnter} />
	);
}

const AppSwitcherPlaceholderButtonForBrowser = forwardRef(
	AppSwitcherPlaceholderButtonForBrowserWithRef,
);

function AppSwitcherPlaceholderButtonForBrowserWithRef(
	{ onOpen, onMouseEnter }: Props,
	ref: Ref<HTMLButtonElement>,
) {
	return (
		<AppSwitcherLightWrapper>
			<AkAppSwitcherNav4
				ref={ref}
				onClick={onOpen}
				onMouseEnter={onMouseEnter}
				label={SwitchToTooltipText}
				aria-expanded={false}
				aria-haspopup
			/>
		</AppSwitcherLightWrapper>
	);
}

const AppSwitcherLightWrapper = componentWithFG(
	'app_switcher_spotlight_gate',
	SpotLightWrapper,
	Fragment,
);

function SpotLightWrapper(props: PropsWithChildren) {
	return (
		<>
			<SpotlightTarget name="app-switcher-spotlight">{props.children}</SpotlightTarget>
			<EngagementSpotlight engagementId="app-switcher-spotlight" />
		</>
	);
}
