import type { JiraSpaRoute, Match, Query, RouterContext } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import type { RouteName } from '@atlassian/jira-route-name-types/src/RouteName.tsx';
import {
	ENTITY_ID,
	MENU_ID_PROJECTS,
	MENU_ID_PROJECTS_VIEW_ALL,
	MENU_ID_PROJECT_APPS,
} from '../../../../constants.tsx';
import type { SelectedPath } from '../../../../types.tsx';

const routes = new Set<RouteName>([
	'browse-project-redirect',
	'legacy-all-reports',
	'projects-directory',
	'issue-navigator',
	'issue-navigator-core',
	'project-components',
	'project-not-found',
	'project-versions',
	'software-version-detail',
	'software-version-detail-no-tab-path',

	'forge-spa-project',
	'forge-spa-project-servicedesk',
	'forge-spa-servicedesk-queue',

	'polaris-ideas',
	'polaris-onboarding',

	'opsgenie-schedule',
	'issue-navigator-classic',
	'project-pages',
	'rapidboard-backlog',
	'rapidboard-board',
	'rapidboard-report',
	'software-backlog',
	'software-boards',
	'software-calendar',
	'software-calendar-classic',
	'classic-software-proforma-issue-forms-direct',
	'software-code',
	'software-code-classic',
	'software-deployments',
	'software-deployments-classic',
	'software-form',
	'software-form-classic',
	'software-form-builder',
	'software-form-builder-classic',
	'software-form-submit',
	'software-form-submit-classic',
	'software-form-submit-embed',
	'software-form-submit-public',
	'software-form-submit-classic-embed',
	'software-form-submit-classic-public',
	'software-goals',
	'software-goals-classic',
	'software-incidents',
	'software-incidents-classic',
	'software-issue-navigator',
	'software-list',
	'software-list-classic',
	'software-periscope',
	'software-periscope-classic',
	'software-reports',
	'software-reports-boardless',
	'software-reports-burndown',
	'software-reports-burnup',
	'software-reports-control',
	'software-reports-cumulative',
	'software-reports-cycle-time',
	'software-reports-cycle-time-classic',
	'software-reports-deployment-frequency',
	'software-reports-deployment-frequency-classic',
	'software-reports-velocity',
	'software-reports-velocity-cmp',
	'software-security',
	'software-security-classic',
	'software-summary',
	'software-summary-embed',
	'software-summary-classic',
	'software-summary-classic-embed',
	'software-timeline',
	'software-timeline-classic',
	'software-view-not-found',
	'software-view-not-found-classic',
	'software-board-view-not-found',
	'software-board-view-not-found-classic',

	'knowledge-hub-all-articles',
	'kb-article-embed-editor',
	'knowledge-hub-article-in-category',
	'knowledge-hub-browse-all-articles',
	'knowledge-hub-browse-all-categories',
	'knowledge-hub-browse-view-article',
	'knowledge-hub-category',
	'knowledge-hub-category-page',
	'knowledge-hub-drafts',
	'knowledge-hub-suggested-topics',
	'knowledge-hub-landing',
	'knowledge-hub-search',
	'knowledge-hub-setup',
	'notification-logs',
	'servicedesk-atlassian-analytics',
	'servicedesk-boards',
	'servicedesk-calendar',
	'servicedesk-project-summary',
	'servicedesk-change-calendar',
	'servicedesk-connect-project',
	'servicedesk-customers-v2',
	'servicedesk-customer-details',
	'servicedesk-customer-service-escalations',
	'servicedesk-customer-service-products',
	'servicedesk-getting-started-page',
	'servicedesk-issue',
	'servicedesk-issues',
	'servicedesk-kb-requests-reports',
	'servicedesk-kb-requests-reports-time-scale-issue',
	'servicedesk-kb-requests-reports-time-scale',
	'servicedesk-oncall-schedule',
	'servicedesk-organizations',
	'servicedesk-organization-details',
	'servicedesk-organization_v2',
	'servicedesk-queues-practice-custom-board',
	'servicedesk-queues-practice-custom-calendar',
	'servicedesk-practice-custom-and-issue',
	'servicedesk-practice-edit',
	'servicedesk-practice-issue',
	'servicedesk-practice-landing',
	'servicedesk-practice-manage-view',
	'servicedesk-practice-new',
	'servicedesk-queues-addon',
	'servicedesk-queues-custom-board',
	'servicedesk-queues-custom-calendar',
	'servicedesk-queues-custom-view',
	'servicedesk-queues-custom-issue-detail',
	'servicedesk-queues-edit',
	'servicedesk-practice-views-config',
	'servicedesk-queues-views-config',
	'servicedesk-queues-issue',
	'servicedesk-queues-manage-view',
	'servicedesk-queues-new',
	'servicedesk-reports',
	'servicedesk-reports-custom',
	'servicedesk-reports-custom-create',
	'servicedesk-reports-custom-edit',
	'servicedesk-reports-custom-time-scale-issue',
	'servicedesk-reports-custom-time-scale',
	'servicedesk-reports-satisfaction',
	'servicedesk-reports-satisfaction-time-scale-issue',
	'servicedesk-reports-satisfaction-time-scale',
	'servicedesk-reports-type',
	'servicedesk-reports-workload',
	'servicedesk-services',
	'servicedesk-service-details',

	'classic-business-approvals',
	'classic-business-attachments',
	'classic-business-board',
	'classic-business-calendar',
	'classic-business-form',
	'classic-business-form-builder',
	'classic-business-form-submit',
	'classic-business-form-submit-public',
	'classic-business-list',
	'classic-business-shortcut',
	'classic-business-summary',
	'classic-business-timeline',
	'classic-business-view-not-found',
	'classic-business-redirect',

	'inbox',
	'inbox-classic',
]);

const routesWithArchivedIssuesRoute = new Set(routes);
routesWithArchivedIssuesRoute.add('archived-issues-directory');

function convert(routeContext: RouterContext<JiraSpaRoute>): SelectedPath {
	const routeName = routeContext.route.name;
	const { match, query } = routeContext;
	const path: string[] = [MENU_ID_PROJECTS];
	return convertRouteToPath({ routeName, match, query, path });
}

function convertNew(args: { routeName: RouteName; match: Match; query: Query }): SelectedPath {
	const { routeName, match, query } = args;
	const path: string[] = [MENU_ID_PROJECTS];
	return convertRouteToPath({ routeName, match, query, path });
}

function convertRouteToPath(args: {
	routeName: RouteName;
	match: Match;
	query: Query;
	path: string[];
}): SelectedPath {
	const { routeName, match, query, path } = args;

	switch (routeName) {
		case 'polaris-onboarding':
			break;

		case 'projects-directory':
			path.push(MENU_ID_PROJECTS_VIEW_ALL);
			break;

		case 'forge-spa-project':
		case 'forge-spa-project-servicedesk':
		case 'forge-spa-servicedesk-queue': {
			const { projectKey, appId } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			path.push(MENU_ID_PROJECT_APPS);
			appId && path.push(ENTITY_ID.APP(appId));
			break;
		}

		case 'knowledge-hub-all-articles':
		case 'kb-article-embed-editor':
		case 'knowledge-hub-article-in-category':
		case 'knowledge-hub-browse-all-articles':
		case 'knowledge-hub-browse-all-categories':
		case 'knowledge-hub-browse-view-article':
		case 'knowledge-hub-category':
		case 'knowledge-hub-category-page':
		case 'knowledge-hub-drafts':
		case 'knowledge-hub-suggested-topics':
		case 'knowledge-hub-landing':
		case 'knowledge-hub-search':
		case 'knowledge-hub-setup': {
			const { projectKey } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			projectKey && path.push(ENTITY_ID.PROJECT_KNOWLEDGE_BASE(projectKey));
			break;
		}

		case 'servicedesk-getting-started-page': {
			const { projectKey } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			projectKey && path.push(ENTITY_ID.PROJECT_JSM_GETTING_STARTED(projectKey));
			break;
		}

		case 'notification-logs': {
			const { projectKey } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			projectKey && path.push(ENTITY_ID.PROJECT_NOTIFICATION_LOGS(projectKey));
			break;
		}

		case 'servicedesk-project-summary': {
			const { projectKey } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			projectKey && path.push(ENTITY_ID.PROJECT_SUMMARY(projectKey));
			break;
		}

		case 'servicedesk-boards': {
			const { projectKey, boardId } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			projectKey && path.push(ENTITY_ID.PROJECT_VIEWS(projectKey));
			boardId && path.push(ENTITY_ID.BOARD(boardId));
			break;
		}
		case 'servicedesk-calendar': {
			const { projectKey } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			projectKey && path.push(ENTITY_ID.PROJECT_VIEWS(projectKey));
			projectKey && path.push(ENTITY_ID.PROJECT_CALENDAR(projectKey));
			break;
		}

		case 'servicedesk-change-calendar': {
			const { projectKey } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			projectKey && path.push(ENTITY_ID.PROJECT_CHANGE_CALENDAR(projectKey));
			break;
		}

		case 'servicedesk-organizations':
		case 'servicedesk-organization-details':
		case 'servicedesk-organization_v2':
		case 'servicedesk-customers-v2':
		case 'servicedesk-customer-details':
		case 'servicedesk-customer-service-products': {
			const { projectKey } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			projectKey && path.push(ENTITY_ID.PROJECT_DIRECTORY(projectKey));
			break;
		}

		case 'servicedesk-customer-service-escalations': {
			const { projectKey } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			projectKey && path.push(ENTITY_ID.PROJECT_ESCALATIONS(projectKey));
			break;
		}

		case 'servicedesk-kb-requests-reports':
		case 'servicedesk-kb-requests-reports-time-scale-issue':
		case 'servicedesk-kb-requests-reports-time-scale':
		case 'servicedesk-reports':
		case 'servicedesk-reports-custom':
		case 'servicedesk-reports-custom-create':
		case 'servicedesk-reports-custom-edit':
		case 'servicedesk-reports-custom-time-scale-issue':
		case 'servicedesk-reports-custom-time-scale':
		case 'servicedesk-reports-satisfaction':
		case 'servicedesk-reports-satisfaction-time-scale-issue':
		case 'servicedesk-reports-satisfaction-time-scale':
		case 'servicedesk-reports-type':
		case 'servicedesk-reports-workload': {
			const { projectKey } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			projectKey && path.push(ENTITY_ID.PROJECT_REPORTS(projectKey));
			break;
		}

		case 'servicedesk-oncall-schedule': {
			const { projectKey } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			projectKey && path.push(ENTITY_ID.PROJECT_ONCALL_SCHEDULE(projectKey));
			break;
		}

		case 'servicedesk-issues': {
			const { projectKey } = match.params;
			const filterId = query.filter || query.filterId;

			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));

			if (projectKey && filterId) {
				path.push(ENTITY_ID.PROJECT_FILTERS(projectKey));
				path.push(ENTITY_ID.PROJECT_ISSUE_FILTER(projectKey, filterId));
			}

			break;
		}

		case 'servicedesk-queues-practice-custom-board':
		case 'servicedesk-queues-practice-custom-calendar':
		case 'servicedesk-practice-custom-and-issue':
		case 'servicedesk-practice-edit':
		case 'servicedesk-practice-issue':
		case 'servicedesk-practice-landing':
		case 'servicedesk-practice-manage-view':
		case 'servicedesk-practice-views-config':
		case 'servicedesk-practice-new': {
			const { projectKey, practiceType, queueId } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));

			projectKey &&
				practiceType &&
				path.push(ENTITY_ID.PROJECT_PRACTICE_QUEUE(projectKey, practiceType));
			queueId && path.push(ENTITY_ID.QUEUE(queueId));
			break;
		}

		case 'servicedesk-queues-addon':
		case 'servicedesk-queues-custom-board':
		case 'servicedesk-queues-custom-calendar':
		case 'servicedesk-queues-custom-view':
		case 'servicedesk-queues-edit':
		case 'servicedesk-queues-issue':
		case 'servicedesk-queues-manage-view':
		case 'servicedesk-queues-views-config':
		case 'servicedesk-queues-new': {
			const { projectKey, queueId } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			projectKey && path.push(ENTITY_ID.PROJECT_QUEUES(projectKey));
			queueId && path.push(ENTITY_ID.QUEUE(queueId));
			break;
		}

		case 'servicedesk-queues-custom-issue-detail': {
			const { projectKey, queueId, issueKey } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			projectKey && path.push(ENTITY_ID.PROJECT_QUEUES(projectKey));
			queueId && path.push(ENTITY_ID.QUEUE(queueId));
			issueKey && path.push(ENTITY_ID.ISSUE(issueKey));
			break;
		}

		case 'servicedesk-services':
		case 'servicedesk-service-details': {
			const { projectKey } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			projectKey && path.push(ENTITY_ID.PROJECT_SERVICES(projectKey));
			break;
		}

		default: {
			const { projectKey, boardId, formId } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			boardId && path.push(ENTITY_ID.BOARD(boardId));
			formId && path.push(ENTITY_ID.FORM(formId));
		}
	}

	if (fg('fix-operations-menu-item-deselected-bug')) {
		const { projectKey } = match.params;

		if (
			(routeName === 'servicedesk-change-calendar' ||
				routeName === 'servicedesk-services' ||
				routeName === 'servicedesk-service-details') &&
			projectKey
		) {
			path.push(ENTITY_ID.PROJECT_OPERATIONS(projectKey));
		}
	}

	return path;
}

export const projectsConverter = {
	get routes() {
		return fg('ken_1984_archive_route_support_sidebar') ? routesWithArchivedIssuesRoute : routes;
	},
	convert,
	convertNew,
};
